"use strict";

var _interopRequireWildcard = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _BarChart = _interopRequireDefault(require("../BarChart"));
var dashbord = _interopRequireWildcard(require("@/api/dashboard"));
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    BarChart: _BarChart.default
  },
  props: ["areaId"],
  data: function data() {
    return {
      date: [(0, _moment.default)().subtract(7, "days").format("YYYY-MM-DD"), (0, _moment.default)().format("YYYY-MM-DD")],
      dataChart: []
    };
  },
  watch: {
    areaId: {
      handler: function handler(val) {
        this.getDataChart();
      }
    }
  },
  created: function created() {
    this.getDataChart();
  },
  methods: {
    getDataChart: function getDataChart() {
      var _this = this;
      var listQuery = {
        from: this.date[0],
        to: this.date[1],
        areaId: this.areaId
      };
      dashbord.orderFoodChart(listQuery).then(function (res) {
        _this.dataChart = res.data.report;
        _this.$nextTick(function () {
          _this.$refs.chart.initChart();
        });
      });
    }
  }
};