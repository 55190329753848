var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "1200px",
        top: "15px",
        title:
          _vm.dialogStatus == "create"
            ? "Thêm mới thông báo"
            : "Cập nhật thông báo",
        visible: _vm.dialogVisible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "80px",
            "label-position": "top",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Tiêu đề", prop: "title" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.formData.title,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "title", $$v)
                                  },
                                  expression: "formData.title",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Mô tả ngắn",
                                prop: "shortDescription",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea" },
                                model: {
                                  value: _vm.formData.shortDescription,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "shortDescription",
                                      $$v
                                    )
                                  },
                                  expression: "formData.shortDescription",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "Số điện thoại", prop: "phone" },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.formData.phone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "phone", $$v)
                                  },
                                  expression: "formData.phone",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "areaId", label: "Khu vực" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "",
                                    clearable: "",
                                    filterable: "",
                                    disabled: _vm.$store.state.user.info.area,
                                  },
                                  model: {
                                    value: _vm.formData.areaId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "areaId", $$v)
                                    },
                                    expression: "formData.areaId",
                                  },
                                },
                                _vm._l(_vm.areas, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { staticClass: "text-center", attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Banner", prop: "thumbnail" } },
                            [
                              _c(
                                "el-upload",
                                {
                                  attrs: {
                                    name: "file",
                                    drag: "",
                                    "before-upload": _vm.beforeUpload,
                                    action:
                                      _vm.$uploadUrl +
                                      "/banner/upload/thumbnail",
                                    "on-success": _vm.handleUploadSuccess,
                                    "show-file-list": false,
                                    headers: {
                                      token: _vm.token,
                                      version: _vm.$version,
                                    },
                                    accept: "image/jpeg, image/png",
                                  },
                                },
                                [
                                  !_vm.formData.thumbnail
                                    ? [
                                        _c("i", {
                                          staticClass: "el-icon-upload",
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "el-upload__text" },
                                          [
                                            _vm._v(
                                              "\n                    Kéo thả vào đây hoặc\n                    "
                                            ),
                                            _c("em", [
                                              _vm._v(
                                                "click vào để upload banner"
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    : _c("img", {
                                        staticStyle: {
                                          width: "100%",
                                          height: "100%",
                                          "object-fit": "contain",
                                        },
                                        attrs: {
                                          src: _vm.$url(_vm.formData.thumbnail),
                                          alt: "",
                                        },
                                      }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 16 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Mô tả", prop: "content" } },
                    [
                      _c("Tinymce", {
                        ref: "editor",
                        attrs: { width: "100%", height: 400 },
                        model: {
                          value: _vm.formData.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "content", $$v)
                          },
                          expression: "formData.content",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitLoading },
              on: {
                click: function ($event) {
                  _vm.dialogStatus == "create"
                    ? _vm.createData()
                    : _vm.updateData()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }