var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-card", { staticStyle: { "margin-bottom": "20px" } }, [
    _c(
      "div",
      { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
      [_c("span", [_vm._v("Thông tin")])]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "user-profile" }, [
      _c(
        "div",
        { staticClass: "box-center" },
        [
          _c(
            "pan-thumb",
            {
              attrs: {
                image: _vm.user.avatar
                  ? _vm.$url(_vm.user.avatar)
                  : require("@/assets/images/avatar_default.svg"),
                height: "100px",
                width: "100px",
                hoverable: false,
              },
            },
            [
              _c("div", [_vm._v("Xin chào")]),
              _vm._v("\n        " + _vm._s(_vm.user.name) + "\n      "),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "box-center" }, [
        _c("div", { staticClass: "user-name text-center" }, [
          _vm._v(_vm._s(_vm.user.name)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "user-role text-center text-muted" }, [
          _vm._v(_vm._s(_vm.user.role.name)),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "user-bio" }, [
      _c("div", { staticClass: "user-education user-bio-section" }, [
        _c(
          "div",
          { staticClass: "user-bio-section-header" },
          [
            _c("svg-icon", { attrs: { "icon-class": "education" } }),
            _vm._v(" "),
            _c("span", [_vm._v("Thông tin khác")]),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "user-bio-section-body" }, [
          _c("div", { staticClass: "text-muted" }, [
            _c("span", { staticStyle: { "font-weight": "bold" } }, [
              _vm._v("Email:"),
            ]),
            _vm._v("\n          " + _vm._s(_vm.user.email) + "\n        "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "text-muted" }, [
            _c("span", { staticStyle: { "font-weight": "bold" } }, [
              _vm._v("Số điện thoại:"),
            ]),
            _vm._v("\n          " + _vm._s(_vm.user.phone) + "\n        "),
          ]),
          _vm._v(" "),
          _vm.user.area
            ? _c("div", { staticClass: "text-muted" }, [
                _c("span", { staticStyle: { "font-weight": "bold" } }, [
                  _vm._v("Khu vực:"),
                ]),
                _vm._v(
                  "\n          " + _vm._s(_vm.user.area.name) + "\n        "
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }