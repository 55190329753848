var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        top: "30px",
        title:
          _vm.dialogStatus == "add" ? "Thêm khách hàng" : "Cập nhật khách hàng",
        visible: _vm.dialogVisible,
        width: _vm.dialogWidth,
        "close-on-click-modal": false,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dialogLoading,
              expression: "dialogLoading",
            },
          ],
        },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "card" },
              model: {
                value: _vm.tabName,
                callback: function ($$v) {
                  _vm.tabName = $$v
                },
                expression: "tabName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "Thông tin", name: "info" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "dataForm",
                      attrs: {
                        disabled: _vm.dialogDisabled,
                        rules: _vm.rules,
                        model: _vm.tempData,
                        "label-position": "top",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "Tên họ tên", prop: "name" },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.tempData.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.tempData, "name", $$v)
                                      },
                                      expression: "tempData.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Ngày sinh",
                                    prop: "dayOfBirth",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      "value-format": "dd/MM/yyyy",
                                      format: "dd/MM/yyyy",
                                      type: "date",
                                      placeholder: "Chọn ngày sinh",
                                    },
                                    model: {
                                      value: _vm.tempData.dayOfBirth,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tempData,
                                          "dayOfBirth",
                                          $$v
                                        )
                                      },
                                      expression: "tempData.dayOfBirth",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Số điện thoại",
                                    prop: "phone",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.tempData.phone,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.tempData, "phone", $$v)
                                      },
                                      expression: "tempData.phone",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "Giới tính", prop: "gender" },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: { placeholder: "" },
                                      model: {
                                        value: _vm.tempData.gender,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.tempData, "gender", $$v)
                                        },
                                        expression: "tempData.gender",
                                      },
                                    },
                                    _vm._l(_vm.enumSex, function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: { label: item, value: index },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "areaId", label: "Khu vực" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: "",
                                        clearable: "",
                                        filterable: "",
                                        disabled:
                                          _vm.$store.state.user.info.area,
                                      },
                                      model: {
                                        value: _vm.tempData.areaId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.tempData, "areaId", $$v)
                                        },
                                        expression: "tempData.areaId",
                                      },
                                    },
                                    _vm._l(_vm.areas, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "Lịch sử đơn hàng", name: "HistoryOrder" } },
                [
                  _c("OrderHistory", {
                    attrs: { customerId: _vm.tempData.id },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _vm._v(" "),
          !_vm.dialogDisabled
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingButton },
                  on: {
                    click: function ($event) {
                      _vm.dialogStatus == "edit"
                        ? _vm.editData()
                        : _vm.createData()
                    },
                  },
                },
                [_vm._v("Đồng ý")]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }