"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _objectSpread2 = _interopRequireDefault(require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.number.constructor");
var _vuex = require("vuex");
var _moment = _interopRequireDefault(require("moment"));
var _s = require("@/plugins/s3");
var _uuid = require("uuid");
var _url = require("@/utils/url");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    width: {
      type: Number,
      default: 360
    },
    height: {
      type: Number,
      default: 180
    },
    disabled: {
      type: Boolean,
      default: false
    },
    prefixFile: {
      type: String,
      default: ""
    },
    fileList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      urlUpload: "",
      isProgressing: false,
      percentUpload: 0
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["$baseUrl", "$cloudFrontUrl", "token"])), {}, {
    computeWidth: function computeWidth() {
      if (isNaN(this.width)) {
        return this.width;
      }
      return "".concat(this.width, "px");
    },
    computeHeight: function computeHeight() {
      if (isNaN(this.height)) {
        return this.height;
      }
      return "".concat(this.height, "px");
    }
  }),
  methods: {
    onProgress: function onProgress(percent) {
      this.percentUpload = +percent.toFixed(0);
      this.emitProgress(this.percentUpload);
    },
    onError: function onError(err, file, fileList) {
      if (err.message) {
        this.$message.error(err.message);
      }
      this.isProgressing = false;
    },
    emitProgress: function emitProgress(percent) {
      this.$emit("progress", percent);
    },
    emitSuccess: function emitSuccess(url, name) {
      this.$emit("upload:success", {
        url: url,
        name: name
      });
    },
    emitRemove: function emitRemove(file) {
      this.$emit("remove:success", file);
    },
    handleSuccess: function handleSuccess(key, name) {
      var url = "".concat(process.env.VUE_APP_CLOUD_FRONT_URL, "/").concat(key);
      console.log("url", url);
      console.log("name", name);
      this.emitSuccess((0, _url.$url)(url), {
        url: name,
        uid: name
      });
      this.isProgressing = false;
    },
    beforeUpload: function beforeUpload(file) {
      var _this = this;
      var isJPG = file.type.includes("image");
      if (!isJPG) {
        this.$message.error("Ảnh không đúng định dạng!");
        return false;
      }
      if (file.size > 1024 * 1024 * 2) {
        this.$message.error("Ảnh kích thước tối đa là 2MB!");
        return false;
      }
      var splitName = file.name.split(".");
      var name = (0, _uuid.v4)();
      var extendName = splitName[splitName.length - 1];
      var key = "".concat(name, "-").concat((0, _moment.default)().unix(), ".").concat(extendName);
      if (this.prefixFile) {
        key = this.prefixFile + "/" + key;
      }
      this.isProgressing = true;
      (0, _s.uploadToS3)(file, key, this.onProgress, function () {
        return _this.handleSuccess(key, file.name);
      }, file.type);
      return false;
    }
  }
};