var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, fit: "" } },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Cửa hàng", width: "200px", prop: "name" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { sortable: "", label: "Địa chỉ" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticStyle: { "padding-left": "12px" } }, [
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Địa chỉ: "),
                        ]),
                        _c("span", [_vm._v(_vm._s(row.address))]),
                      ]),
                      _vm._v(" "),
                      row.area
                        ? _c("li", [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Khu vực: "),
                            ]),
                            _c("span", [_vm._v(_vm._s(row.area.name))]),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Loại cửa hàng" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("div", [_vm._v(_vm._s(_vm.STORE_TYPE[row.type]))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "totalOrder", label: "Đơn hàng" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("p", { staticClass: "chart-title" }, [_vm._v("Top 20 cửa hàng")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }