"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
require("core-js/modules/es6.number.constructor");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    customerId: Number
  },
  data: function data() {
    return {
      tabName: "food"
    };
  },
  components: {
    OrderFood: function OrderFood() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./OrderFood"));
      });
    },
    OrderDelivery: function OrderDelivery() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./OrderDelivery"));
      });
    },
    OrderTransport: function OrderTransport() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./OrderTransport"));
      });
    }
  }
};