var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.dialogStatus == "update" ? "Cập nhật banner" : "Thêm banner",
        visible: _vm.dialogVisible,
        width: "500px",
        top: "15px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dialogLoading,
              expression: "dialogLoading",
            },
          ],
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                "label-width": "80px",
                "label-position": "top",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Tiêu đề", prop: "name" } },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.formData.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formData, "name", $$v)
                                      },
                                      expression: "formData.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "Nội dung", prop: "content" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      autosize: { minRows: 2, maxRows: 4 },
                                    },
                                    model: {
                                      value: _vm.formData.content,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formData, "content", $$v)
                                      },
                                      expression: "formData.content",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "areaId", label: "Khu vực" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: "",
                                        filterable: "",
                                        disabled:
                                          _vm.$store.state.user.info.area,
                                      },
                                      on: { change: _vm.onChangeArea },
                                      model: {
                                        value: _vm.formData.areaId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.formData, "areaId", $$v)
                                        },
                                        expression: "formData.areaId",
                                      },
                                    },
                                    _vm._l(_vm.areas, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.formData.SelectTypeList != true
                            ? _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Cửa hàng",
                                        prop: "storeId",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            disabled: !_vm.formData.areaId,
                                            filterable: "",
                                            placeholder: "",
                                            remote: "",
                                            clearable: "",
                                            "remote-method": _vm.fetchStore,
                                          },
                                          model: {
                                            value: _vm.formData.storeId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "storeId",
                                                $$v
                                              )
                                            },
                                            expression: "formData.storeId",
                                          },
                                        },
                                        _vm._l(_vm.stores, function (item) {
                                          return _c("el-option", {
                                            key: item.id,
                                            attrs: {
                                              label: item.name,
                                              value: item.id,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "layout", label: "Vị trí" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: { placeholder: "" },
                                      model: {
                                        value: _vm.formData.layout,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.formData, "layout", $$v)
                                        },
                                        expression: "formData.layout",
                                      },
                                    },
                                    _vm._l(
                                      _vm.HomeBannerLayoutTrans,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: { label: item, value: index },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticClass: "text-center", attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "Banner", prop: "imageUrl" },
                                },
                                [
                                  _c(
                                    "el-upload",
                                    {
                                      attrs: {
                                        name: "file",
                                        drag: "",
                                        "before-upload": _vm.beforeUpload,
                                        action:
                                          _vm.$uploadUrl +
                                          "/banner/upload/thumbnail",
                                        "on-success": _vm.handleUploadSuccess,
                                        "show-file-list": false,
                                        headers: {
                                          token: _vm.token,
                                          version: _vm.$version,
                                        },
                                        accept: "image/jpeg, image/png",
                                      },
                                    },
                                    [
                                      !_vm.formData.imageUrl
                                        ? [
                                            _c("i", {
                                              staticClass: "el-icon-upload",
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "el-upload__text",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      Kéo thả vào đây hoặc\n                      "
                                                ),
                                                _c("em", [
                                                  _vm._v(
                                                    "click vào để upload banner"
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        : _c("img", {
                                            staticStyle: {
                                              width: "100%",
                                              height: "100%",
                                              "object-fit": "contain",
                                            },
                                            attrs: {
                                              src: _vm.$url(
                                                _vm.formData.imageUrl
                                              ),
                                              alt: "",
                                            },
                                          }),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.submitLoading, type: "primary" },
              on: {
                click: function ($event) {
                  _vm.dialogStatus == "create"
                    ? _vm.createData()
                    : _vm.updateData()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }