"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _date = require("@/utils/date");
var _transaction = require("@/api/transaction");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ["driverId"],
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      data: [],
      listQuery: {
        page: 1,
        limit: 10,
        balanceType: null,
        search: ""
      },
      total: 0,
      loading: false,
      date: [(0, _moment.default)().subtract(1, "months").format("YYYY-MM-DD"), (0, _moment.default)().format("YYYY-MM-DD")]
    };
  },
  watch: {
    driverId: {
      handler: function handler(val) {
        if (val) {
          this.resetQuery();
          this.fetchData();
        }
      },
      immediate: true
    }
  },
  methods: {
    resetQuery: function resetQuery() {
      this.listQuery = {
        page: 1,
        limit: 10,
        balanceType: null,
        search: ""
      };
      this.date = [(0, _moment.default)().subtract(1, "months").format("YYYY-MM-DD"), (0, _moment.default)().format("YYYY-MM-DD")];
    },
    formatDateTime: function formatDateTime(n) {
      return (0, _date.formatDateTime)(n);
    },
    fetchData: function fetchData() {
      var _this = this;
      this.listQuery.from = this.date[0];
      this.listQuery.to = this.date[1];
      this.listQuery.driverId = this.driverId;
      this.loading = true;
      _transaction.transactionApi.findAll(this.listQuery).then(function (res) {
        _this.data = res.data.data;
        _this.total = res.data.total;
      }).finally(function () {
        _this.loading = false;
      });
    }
  }
};