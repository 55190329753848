"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.account = account;
exports.approve = approve;
exports.create = create;
exports.findAll = findAll;
exports.findOne = findOne;
exports.remove = remove;
exports.requestStore = requestStore;
exports.update = update;
var _request = _interopRequireDefault(require("@/utils/request"));
function findAll(listQuery) {
  return (0, _request.default)({
    url: "/store",
    method: "get",
    params: listQuery
  });
}
function findOne(id) {
  return (0, _request.default)({
    url: "/store/".concat(id),
    method: "get"
  });
}
function create(data) {
  return (0, _request.default)({
    url: "/store",
    method: "post",
    data: data
  });
}
function update(storeId, data) {
  return (0, _request.default)({
    url: "/store/".concat(storeId, "/update"),
    method: "post",
    data: data
  });
}
function requestStore(storeId) {
  return (0, _request.default)({
    url: "/store/".concat(storeId, "/request")
  });
}
function approve(storeId, requestId) {
  return (0, _request.default)({
    url: "/store/".concat(storeId, "/request/").concat(requestId, "/approve")
  });
}
function account(storeId, data) {
  return (0, _request.default)({
    url: "/store/".concat(storeId, "/password/update"),
    method: "post",
    data: data
  });
}
function remove(storeId) {
  return (0, _request.default)({
    url: "/store/".concat(storeId, "/delete"),
    method: "post"
  });
}