"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.customerChart = customerChart;
exports.getDashboard = getDashboard;
exports.orderDeliveryChart = orderDeliveryChart;
exports.orderFoodChart = orderFoodChart;
exports.orderTransportChart = orderTransportChart;
exports.top5Customer = top5Customer;
exports.top5Driver = top5Driver;
exports.topStore = topStore;
var _request = _interopRequireDefault(require("@/utils/request"));
function getDashboard(params) {
  return (0, _request.default)({
    url: "/dashboard",
    method: "get",
    params: params
  });
}
function customerChart(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/customerLast30",
    method: "get",
    params: listQuery
  });
}
function orderFoodChart(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/orderFoodLast30",
    method: "get",
    params: listQuery
  });
}
function orderDeliveryChart(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/orderDeliveryLast30",
    method: "get",
    params: listQuery
  });
}
function orderTransportChart(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/orderTransportLast30",
    method: "get",
    params: listQuery
  });
}
function top5Driver(params) {
  return (0, _request.default)({
    url: "/dashboard/top5DriversThisMonth",
    method: "get",
    params: params
  });
}
function top5Customer(params) {
  return (0, _request.default)({
    url: "/dashboard/top5CustomerThisMonth",
    method: "get",
    params: params
  });
}
function topStore(params) {
  return (0, _request.default)({
    url: "/dashboard/top/store",
    method: "get",
    params: params
  });
}