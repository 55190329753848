import { render, staticRenderFns } from "./OrderTransport.vue?vue&type=template&id=65a1a120"
import script from "./OrderTransport.vue?vue&type=script&lang=js"
export * from "./OrderTransport.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/mac/Documents/project/050/050admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('65a1a120')) {
      api.createRecord('65a1a120', component.options)
    } else {
      api.reload('65a1a120', component.options)
    }
    module.hot.accept("./OrderTransport.vue?vue&type=template&id=65a1a120", function () {
      api.rerender('65a1a120', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/dashboard/admin/components/chart/OrderTransport.vue"
export default component.exports