"use strict";

var _interopRequireWildcard = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _DetailFoodModal = _interopRequireDefault(require("@/views/order/food/components/DetailFoodModal.vue"));
var order = _interopRequireWildcard(require("@/api/order/food"));
var _date = require("@/utils/date");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _format = require("@/utils/format");
var _enum = require("@/utils/enum");
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Pagination: _Pagination.default,
    DetailFoodModal: _DetailFoodModal.default
  },
  props: {
    storeId: {
      type: Number,
      default: null
    },
    type: {
      type: String,
      default: ""
    },
    driverId: {
      type: Number,
      default: null
    },
    showStatusCol: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      totalMoneyTotal: 0,
      totalRevenue: 0,
      totalIncome: 0,
      ORDER_FOOD_STATUS: _enum.ORDER_FOOD_STATUS,
      listLoading: false,
      total: 0,
      totalMoney: 0,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      listQuery: {
        page: 1,
        limit: 10,
        search: "",
        isBlock: null,
        status: null,
        fromMoneyProduct: null,
        toMoneyProduct: null,
        storeId: null
      },
      tableData: [],
      date: [(0, _moment.default)().subtract(7, "days").startOf("day").toDate(), (0, _moment.default)().endOf("days").endOf("day").toDate()],
      tagOptions: _enum.tagOptions
    };
  },
  created: function created() {
    if (!this.driverId) {
      this.getTableData();
    }
  },
  watch: {
    storeId: function storeId() {
      this.getTableData();
    }
  },
  methods: {
    detailFood: function detailFood(order) {
      this.$refs["detail-food"].handleOpen(order);
    },
    calcSumPrice: function calcSumPrice(data) {
      var sum = data.reduce(function (prev, cur) {
        return prev + cur.amount * cur.finalPrice;
      }, 0);
      return sum;
    },
    handleView: function handleView(row) {
      this.$refs["dialog"].handleView(row);
    },
    viewStore: function viewStore(store) {
      this.$refs["store-dialog"].handleEdit(store);
    },
    viewCustomer: function viewCustomer(customer) {
      this.$refs["customer-dialog"].handleEdit(customer);
    },
    viewDriver: function viewDriver(driver) {
      this.$refs["driver-dialog"].handleEdit(driver);
    },
    formatNumberVN: function formatNumberVN(n) {
      return (0, _format.formatNumberVN)(n);
    },
    formatCurrency: function formatCurrency(n) {
      return (0, _format.formatCurrency)(n);
    },
    changeStatus: function changeStatus(row) {
      var _this = this;
      this.listLoading = true;
      var data = {
        orderFood: {
          status: row.status
        }
      };
      order.update(row.id, data).then(function () {
        _this.$notify({
          type: "success",
          message: "Thành công"
        });
        _this.getTableData();
      }).finally(function () {
        _this.listLoading = false;
      });
    },
    getTotalMoney: function getTotalMoney() {
      var _this2 = this;
      var listQuery = {
        from: this.listQuery.from,
        to: this.listQuery.to
      };
      var data = [];
      order.findAll(listQuery).then(function (res) {
        data = res.data.data;
        var total = 0;
        data.forEach(function (element) {
          if (element.status == "COMPLETE") total += element.moneyFinal - element.moneyIncome;
        });
        _this2.totalMoney = total;
      });
    },
    getTableData: function getTableData() {
      var _this3 = this;
      if (this.listQuery.fromMoneyProduct && !this.listQuery.toMoneyProduct || !this.listQuery.fromMoneyProduct && this.listQuery.toMoneyProduct) {
        return this.$alert("Vui lòng điền đầy đủ giá tiền cao nhất và giá tiền thấp nhất.", "Lưu ý", {
          confirmButtonText: "OK"
        });
      }
      this.listLoading = true;
      this.listQuery.storeId = this.storeId;
      this.listQuery.from = this.date ? this.date[0] : undefined;
      this.listQuery.to = this.date ? this.date[1] : undefined;
      order.findAll(this.listQuery).then(function (res) {
        _this3.tableData = res.data.data;
        _this3.totalRevenue = res.data.totalRevenue;
        _this3.totalIncome = res.data.totalIncome;
        _this3.total = res.data.total;
        _this3.totalMoneyTotal = res.data.totalMoneyTotal;
      }).finally(function () {
        _this3.listLoading = false;
      });
    },
    formatDateTime: function formatDateTime(timestamp) {
      return (0, _date.formatDateTime)(timestamp);
    }
  }
};