"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteVariant = deleteVariant;
exports.getVariant = getVariant;
exports.patchVariant = patchVariant;
exports.postVariant = postVariant;
var _request = _interopRequireDefault(require("@/utils/request"));
function getVariant(params) {
  return (0, _request.default)({
    url: "/foodVariation",
    method: "get",
    params: params
  });
}
function postVariant(data) {
  return (0, _request.default)({
    url: "/foodVariation/",
    method: "post",
    data: data
  });
}
function patchVariant(foodId, data) {
  return (0, _request.default)({
    url: "/foodVariation/".concat(foodId),
    method: "patch",
    data: data
  });
}
function deleteVariant(foodId) {
  return (0, _request.default)({
    url: "/foodVariation/".concat(foodId),
    method: "delete"
  });
}