"use strict";

var _interopRequireWildcard = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _objectSpread2 = _interopRequireDefault(require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var driver = _interopRequireWildcard(require("@/api/driver"));
var _format = require("@/utils/format");
var _vuex = require("vuex");
var _OrderHistory = _interopRequireDefault(require("./OrderHistory"));
var _PayHistory = _interopRequireDefault(require("./PayHistory"));
var _Transaction = _interopRequireDefault(require("./Transaction"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "DriverDialog",
  props: {
    dialogDisabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    OrderHistory: _OrderHistory.default,
    PayHistory: _PayHistory.default,
    Transaction: _Transaction.default
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["token"])), (0, _vuex.mapState)({
    areas: function areas(state) {
      return state.area.list;
    }
  })),
  watch: {
    tabName: function tabName(val) {
      switch (val) {
        case "order-history":
          this.dialogWith = "1300px";
          break;
        case "info":
          this.dialogWith = "800px";
          break;
        case "pay-history":
        case "transaction":
          this.dialogWith = "1300px";
          break;
        default:
          break;
      }
    }
  },
  data: function data() {
    return {
      dialogWith: "800px",
      tabName: "info",
      dialogLoading: false,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      tempData: {},
      avatar: "",
      dialogStatus: "",
      dialogVisible: false,
      loadingButton: false,
      roles: [],
      roleId: 1,
      rules: {
        name: {
          required: true,
          message: "Vui lòng nhập tên",
          trigger: "submit"
        },
        username: {
          required: true,
          message: "Vui lòng nhập tài khoản",
          trigger: "submit"
        },
        phone: {
          required: true,
          message: "Vui lòng nhập số điện thoại",
          trigger: "submit"
        },
        password: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nhập nhập mật khẩu"
        },
        licensePlate: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nhập nhập biển số"
        },
        areaId: {
          required: true,
          trigger: "submit",
          message: "Vui lòng chọn khu vực"
        }
      }
    };
  },
  methods: {
    errorUpload: function errorUpload(err) {
      this.dialogLoading = false;
    },
    formatCurrency: function formatCurrency(n) {
      return (0, _format.formatCurrency)(n);
    },
    editData: function editData() {
      var _this = this;
      this.$refs["dataForm"].validate(function (valid) {
        if (valid) {
          var data = {
            driver: _this.tempData,
            areaId: _this.tempData.areaId
          };
          _this.loadingButton = true;
          driver.update(_this.tempData.id, data).then(function (res) {
            if (res.status) {
              _this.$message({
                type: "success",
                message: "Cập nhật thành công"
              });
              _this.dialogVisible = false;
              _this.$emit("update:success");
            }
          }).finally(function () {
            _this.loadingButton = false;
          });
        }
      });
    },
    handleEdit: function handleEdit(data) {
      var _this2 = this;
      this.dialogStatus = "edit";
      this.dialogVisible = true;
      this.$nextTick(function () {
        _this2.$refs["dataForm"].clearValidate();
        _this2.tempData = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, data), {}, {
          areaId: data.area && data.area.id
        });
      });
    },
    handleAdd: function handleAdd() {
      var _this3 = this;
      this.dialogVisible = true;
      this.dialogStatus = "add";
      this.tabName = "info";
      this.$nextTick(function () {
        _this3.$refs["dataForm"].resetFields();
        _this3.tempData = {};
        if (_this3.$store.state.user.info.area) {
          _this3.$set(_this3.tempData, "areaId", _this3.$store.state.user.info.area.id);
        }
      });
    },
    createData: function createData() {
      var _this4 = this;
      this.$refs["dataForm"].validate(function (valid) {
        if (valid) {
          var data = {
            driver: _this4.tempData,
            areaId: _this4.tempData.areaId
          };
          _this4.loadingButton = true;
          driver.create(data).then(function (res) {
            if (res.status) {
              _this4.$message({
                type: "success",
                message: "Thêm tài xế thành công"
              });
              _this4.dialogVisible = false;
              _this4.$refs["dataForm"].resetFields();
              _this4.$emit("create:success");
            }
          }).finally(function () {
            _this4.loadingButton = false;
          });
        }
      });
    },
    handleAvatarSuccess: function handleAvatarSuccess(res, file) {
      this.dialogLoading = false;
      this.$set(this.tempData, "avatar", res.data.path);
    },
    beforeUpload: function beforeUpload(file) {
      this.dialogLoading = true;
      var isJPG = file.type.includes("image");
      var isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.dialogLoading = false;
        this.$message.error("Chỉ chấp nhận ảnh dưới 2MB");
      }
      if (!isJPG) {
        this.dialogLoading = false;
        this.$message.error("Avatar picture must be image format!");
      }
      return isJPG && isLt2M;
    },
    getData: function getData(userId) {
      var _this5 = this;
      this.dialogVisible = true;
      this.dialogStatus = "edit";
      user.findOne(userId).then(function (res) {
        _this5.tempData = res.data;
      });
    },
    handleReset: function handleReset() {
      var _this6 = this;
      this.$prompt("Nhập mật khẩu mới để khôi phục", "Khôi phục mật khẩu", {
        confirmButtonText: "OK",
        cancelButtonText: "Hủy"
      }).then(function (_ref) {
        var value = _ref.value;
        var data = {
          newPassword: value
        };
        driver.resetPassword(_this6.tempData.id, data).then(function (res) {
          _this6.$notify({
            type: "success",
            message: "Khôi phục mật khẩu thành công"
          });
          _this6.dialogVisible = false;
          _this6.$emit("create:success");
        });
      });
    },
    handleUnBlock: function handleUnBlock() {
      var _this7 = this;
      this.$confirm("Tài khoản tài xế này sẽ mở khóa, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        var data = {
          driver: {
            isBlock: false
          }
        };
        driver.update(_this7.tempData.id, data).then(function () {
          _this7.$message({
            type: "success",
            message: "Mở khóa tài khoản thành công"
          });
          _this7.dialogVisible = false;
          _this7.$emit("create:success");
        });
      });
    },
    handleBlock: function handleBlock() {
      var _this8 = this;
      this.$confirm("Tài khoản tài xế này sẽ bị khóa, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        var data = {
          driver: {
            isBlock: true
          }
        };
        driver.update(_this8.tempData.id, data).then(function () {
          _this8.$message({
            type: "success",
            message: "Thành công"
          });
          _this8.dialogVisible = false;
          _this8.$emit("create:success");
        });
      });
    },
    handleDelete: function handleDelete() {
      var _this9 = this;
      this.$confirm("Tài khoản tài xế này sẽ bị xoá, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        driver.remove(_this9.tempData.id).then(function () {
          _this9.$message({
            type: "success",
            message: "Thành công"
          });
          _this9.dialogVisible = false;
          _this9.$emit("create:success");
        });
      });
    }
  }
};