"use strict";

var _interopRequireWildcard = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _date = require("@/utils/date");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var store = _interopRequireWildcard(require("@/api/store"));
var _StoreDialog = _interopRequireDefault(require("./components/StoreDialog"));
var _format = require("@/utils/format");
var enumConst = _interopRequireWildcard(require("@/utils/enum"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Pagination: _Pagination.default,
    StoreDialog: _StoreDialog.default
  },
  data: function data() {
    return {
      listLoading: false,
      total: 0,
      STORE_TYPE: enumConst.STORE_TYPE,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      listQuery: {
        page: 1,
        limit: 10,
        search: "",
        isBlock: null
      },
      tableData: []
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    areas: function areas(state) {
      return state.area.list;
    }
  })),
  created: function created() {
    this.getTableData();
    this.$store.dispatch("area/fetchList");
  },
  methods: {
    formatCurrency: function formatCurrency(n) {
      return (0, _format.formatCurrency)(n);
    },
    handleEdit: function handleEdit(row) {
      this.$refs["dialog"].handleEdit(row);
    },
    handleAdd: function handleAdd() {
      this.$refs["dialog"].handleAdd();
    },
    handleImgError: function handleImgError(event) {
      event.currentTarget.src = require("@/assets/images/avatar_default.svg");
    },
    handleHighlight: function handleHighlight(row) {
      var _this = this;
      var data = {
        store: {
          isHighlight: !row.isHighlight
        }
      };
      store.update(row.id, data).then(function () {
        row.isHighlight = !row.isHighlight;
        _this.$message({
          type: "success",
          message: "Cập nhật thành công"
        });
      });
    },
    handlePromotion: function handlePromotion(row) {
      var _this2 = this;
      var data = {
        store: {
          isPromotion: !row.isPromotion
        }
      };
      store.update(row.id, data).then(function () {
        row.isPromotion = !row.isPromotion;
        _this2.$message({
          type: "success",
          message: "Cập nhật thành công"
        });
      });
    },
    handleSuggest: function handleSuggest(row) {
      var _this3 = this;
      var data = {
        store: {
          isSuggest: !row.isSuggest
        }
      };
      store.update(row.id, data).then(function () {
        row.isSuggest = !row.isSuggest;
        _this3.$message({
          type: "success",
          message: "Cập nhật thành công"
        });
      });
    },
    handleUnBlock: function handleUnBlock(row) {
      var _this4 = this;
      this.$confirm("Cửa hàng này sẽ được mở khóa, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        var data = {
          store: {
            isBlock: false
          }
        };
        store.update(row.id, data).then(function () {
          row.isBlock = false;
          _this4.$message({
            type: "success",
            message: "Mở khóa cửa hàng thành công"
          });
        });
      });
    },
    handleBlock: function handleBlock(row) {
      var _this5 = this;
      this.$confirm("Cửa hàng này sẽ bị khóa, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        var data = {
          store: {
            isBlock: true
          }
        };
        store.update(row.id, data).then(function () {
          row.isBlock = true;
          _this5.$message({
            type: "success",
            message: "Khóa cửa hàng thành công"
          });
        });
      });
    },
    handleDelete: function handleDelete(row) {
      var _this6 = this;
      this.$confirm("Cửa hàng này sẽ bị xóa, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        store.remove(row.id).then(function () {
          _this6.$message({
            type: "success",
            message: "Xóa thành công"
          });
          _this6.getTableData();
        });
      });
    },
    getTableData: function getTableData() {
      var _this7 = this;
      this.listLoading = true;
      store.findAll(this.listQuery).then(function (res) {
        _this7.tableData = res.data.data;
        _this7.total = res.data.total;
      }).finally(function () {
        _this7.listLoading = false;
      });
    },
    formatDateTime: function formatDateTime(timestamp) {
      return (0, _date.formatDateTime)(timestamp);
    }
  }
};