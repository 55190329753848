var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "300px" },
            attrs: {
              "prefix-icon": "el-icon-search",
              placeholder: "Tìm code, tên, sđt",
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.getTableData($event)
              },
            },
            model: {
              value: _vm.listQuery.search,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "search", $$v)
              },
              expression: "listQuery.search",
            },
          }),
          _vm._v(" "),
          _c("el-date-picker", {
            staticClass: "filter-item",
            attrs: {
              format: "dd/MM/yyyy HH:mm",
              "value-format": "yyyy-MM-dd HH:mm",
              type: "datetimerange",
              "range-separator": "To",
              "default-time": ["00:00:00", "23:59:00"],
              "start-placeholder": "Ngày bắt đầu",
              "end-placeholder": "Ngày kết thúc",
              "picker-options": _vm.pickerOptions,
            },
            model: {
              value: _vm.date,
              callback: function ($$v) {
                _vm.date = $$v
              },
              expression: "date",
            },
          }),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              attrs: { placeholder: "Loại đơn" },
              model: {
                value: _vm.listQuery.status,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "status", $$v)
                },
                expression: "listQuery.status",
              },
            },
            [
              _c("el-option", { attrs: { value: null, label: "Trạng thái" } }),
              _vm._v(" "),
              _vm._l(_vm.ORDER_FOOD_STATUS, function (item, index) {
                return _c("el-option", {
                  key: index,
                  attrs: { label: item, value: index },
                })
              }),
            ],
            2
          ),
          _vm._v(" "),
          !_vm.$store.state.user.info.area
            ? _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  staticStyle: { width: "140px" },
                  attrs: { placeholder: "Chọn khu vực" },
                  on: { change: _vm.getTableData },
                  model: {
                    value: _vm.listQuery.areaId,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "areaId", $$v)
                    },
                    expression: "listQuery.areaId",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "Tất cả", value: null } }),
                  _vm._v(" "),
                  _vm._l(_vm.areas, function (area) {
                    return _c("el-option", {
                      key: area.id,
                      attrs: { label: area.name, value: area.id },
                    })
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.listQuery.fromMoneyProduct,
                expression: "listQuery.fromMoneyProduct",
              },
              {
                name: "mask",
                rawName: "v-mask",
                value: {
                  alias: "decimal",
                  groupSeparator: ".",
                  rightAlign: false,
                  autoGroup: true,
                  repeat: 20,
                  autoUnmask: true,
                  allowMinus: false,
                },
                expression:
                  "{\n        alias: 'decimal',\n        groupSeparator: '.',\n        rightAlign: false,\n        autoGroup: true,\n        repeat: 20,\n        autoUnmask: true,\n        allowMinus: false\n      }",
              },
            ],
            staticClass: "filter-item",
            staticStyle: {
              width: "300px",
              "font-size": "13px",
              height: "32px",
            },
            attrs: { placeholder: "Giá tiền thấp nhất", type: "text" },
            domProps: { value: _vm.listQuery.fromMoneyProduct },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.listQuery, "fromMoneyProduct", $event.target.value)
              },
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.getTableData($event)
              },
            },
          }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.listQuery.toMoneyProduct,
                expression: "listQuery.toMoneyProduct",
              },
              {
                name: "mask",
                rawName: "v-mask",
                value: {
                  alias: "decimal",
                  groupSeparator: ".",
                  rightAlign: false,
                  autoGroup: true,
                  repeat: 20,
                  autoUnmask: true,
                  allowMinus: false,
                },
                expression:
                  "{\n        alias: 'decimal',\n        groupSeparator: '.',\n        rightAlign: false,\n        autoGroup: true,\n        repeat: 20,\n        autoUnmask: true,\n        allowMinus: false\n      }",
              },
            ],
            staticClass: "filter-item",
            staticStyle: {
              width: "300px",
              "font-size": "13px",
              height: "32px",
            },
            attrs: { placeholder: "Giá tiền cao nhất", type: "text" },
            domProps: { value: _vm.listQuery.toMoneyProduct },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.listQuery, "toMoneyProduct", $event.target.value)
              },
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.getTableData($event)
              },
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function ($event) {
                  return _vm.getTableData()
                },
              },
            },
            [_vm._v("Tìm")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("p", { staticStyle: { "font-weight": "bold" } }, [
        _vm._v("\n    Tổng lợi nhuận:\n    "),
        _c("span", { staticClass: "increase" }, [
          _vm._v(_vm._s(_vm.formatCurrency(_vm.totalRevenue))),
        ]),
      ]),
      _vm._v(" "),
      _c("p", { staticStyle: { "font-weight": "bold" } }, [
        _vm._v("\n    Tổng chiết khấu:\n    "),
        _c("span", { staticClass: "increase" }, [
          _vm._v(_vm._s(_vm.formatCurrency(_vm.totalIncome))),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { data: _vm.tableData, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Thông tin", prop: "code", width: "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticStyle: { "padding-left": "12px" } }, [
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(" Mã đơn: "),
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(row.code) + " ")]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Tài xế: "),
                        ]),
                        _c(
                          "span",
                          [
                            !row.driver
                              ? _c("span", [_vm._v("--")])
                              : _c(
                                  "el-link",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewDriver(row.driver)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(row.driver.username) +
                                        " - " +
                                        _vm._s(row.driver.name) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Khách hàng: "),
                        ]),
                        _c(
                          "span",
                          [
                            !row.customer
                              ? _c("span", [_vm._v("--")])
                              : _c(
                                  "el-link",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewCustomer(row.customer)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          row.customer.name ||
                                            row.customer.phone
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Khoảng cách: "),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.formatNumberVN(row.distance)) + " Km"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Giao đến: "),
                        ]),
                        _c("span", [_vm._v(" " + _vm._s(row.startAddress))]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Khu vực: "),
                        ]),
                        _vm._v(" "),
                        !row.area
                          ? _c("span", [_vm._v("--")])
                          : _c("span", [_vm._v(" " + _vm._s(row.area.name))]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Ghi chú: "),
                        ]),
                        _vm._v(" "),
                        !row.note
                          ? _c("span", [_vm._v("--")])
                          : _c("span", [_vm._v(" " + _vm._s(row.note))]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type:
                            _vm.tagOptions[row.status] &&
                            _vm.tagOptions[row.status].type,
                          effect:
                            _vm.tagOptions[row.status] &&
                            _vm.tagOptions[row.status].effect,
                        },
                      },
                      [_vm._v(_vm._s(_vm.ORDER_FOOD_STATUS[row.status]))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Cửa hàng / Món " },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    !row.store
                      ? _c("span", [_vm._v("--")])
                      : [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.viewStore(row.store)
                                },
                              },
                            },
                            [_vm._v("-" + _vm._s(row.store.name))]
                          ),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.detailFood(row)
                                },
                              },
                            },
                            [_vm._v("- " + _vm._s(row.totalFood) + " món")]
                          ),
                        ],
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "Chi tiết",
              "min-width": "300px",
              "header-align": "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticClass: "pay" }, [
                      _c("li", [
                        _c("span", [_vm._v("Số tiền theo Km:")]),
                        _vm._v(" "),
                        _c("span", { staticClass: "increase" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.formatCurrency(row.moneyDistance)) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("Số tiền đơn hàng:")]),
                        _vm._v(" "),
                        _c("span", { staticClass: "increase" }, [
                          _vm._v(_vm._s(_vm.formatCurrency(row.moneyTotal))),
                        ]),
                      ]),
                      _vm._v(" "),
                      row.moneyDiscountProduct || row.moneyDiscountShipFee
                        ? _c("li", [
                            _c("span", [_vm._v("Voucher giảm giá:")]),
                            _vm._v(" "),
                            _c("span", { staticClass: "decrease" }, [
                              _vm._v(
                                "-\n              " +
                                  _vm._s(
                                    _vm.formatCurrency(
                                      row.moneyDiscountProduct ||
                                        row.moneyDiscountShipFee
                                    )
                                  )
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("Tổng tiền:")]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.formatCurrency(row.moneyFinal))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("Chiết khấu tài xế:")]),
                        _vm._v(" "),
                        _c("span", { staticClass: "increase" }, [
                          _vm._v(
                            "+ " + _vm._s(_vm.formatCurrency(row.moneyIncome))
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Lợi nhuận", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.status == "COMPLETE"
                      ? _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.formatCurrency(
                                  row.moneyDistance - row.moneyIncome
                                )
                              ) +
                              "\n        "
                          ),
                        ])
                      : _c("span", [_vm._v("--")]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              sortable: "",
              label: "Ngày tạo",
              prop: "dateCreated",
              align: "right",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.formatDateTime(row.dateCreated)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _vm.showStatusCol
            ? _c("el-table-column", {
                attrs: { label: "Hành động" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _vm.ORDER_FOOD_STATUS[row.status] !=
                            _vm.ORDER_FOOD_STATUS.WAITING &&
                          _vm.ORDER_FOOD_STATUS[row.status] !=
                            _vm.ORDER_FOOD_STATUS.COMPLETE
                            ? _c(
                                "el-select",
                                {
                                  staticClass: "filter-item",
                                  attrs: { placeholder: "Trạng thái" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.changeStatus($event, row)
                                    },
                                  },
                                },
                                _vm._l(
                                  _vm.ORDER_ACTION,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: { label: item, value: index },
                                    })
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2903133426
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getTableData,
        },
      }),
      _vm._v(" "),
      _c("CustomerDialog", {
        ref: "customer-dialog",
        attrs: { dialogDisabled: true },
      }),
      _vm._v(" "),
      _c("driver-dialog", {
        ref: "driver-dialog",
        attrs: { dialogDisabled: true },
      }),
      _vm._v(" "),
      _c("StoreDialog", {
        ref: "store-dialog",
        attrs: { dialogDisabled: true },
      }),
      _vm._v(" "),
      _c("DetailFoodModal", { ref: "detail-food" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }