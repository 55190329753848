var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          !_vm.$store.state.user.info.area
            ? _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  staticStyle: { width: "140px" },
                  attrs: { placeholder: "Chọn khu vực" },
                  on: { change: _vm.getData },
                  model: {
                    value: _vm.listQuery.areaId,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "areaId", $$v)
                    },
                    expression: "listQuery.areaId",
                  },
                },
                _vm._l(_vm.areas, function (area) {
                  return _c("el-option", {
                    key: area.id,
                    attrs: { label: area.name, value: area.id },
                  })
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "item-box", attrs: { gutter: 20 } },
        _vm._l(_vm.tableData, function (item, index) {
          return _c(
            "el-col",
            { key: index, attrs: { span: 4, md: 6, xl: 4 } },
            [
              _c(
                "div",
                {
                  staticClass: "item",
                  on: {
                    click: function ($event) {
                      return _vm.handleEdit(item)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "info-box" }, [
                    _c("div", { staticClass: "info-item" }, [
                      _c("label", { staticClass: "type-info" }, [
                        _vm._v("Loại:"),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "value-info" }, [
                        _vm._v(_vm._s(_vm.ORDER_TYPE[item.type])),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "info-item" }, [
                      _c("label", { staticClass: "type-info" }, [
                        _vm._v("Giá thấp nhất:"),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "value-info" }, [
                        _vm._v(_vm._s(_vm.formatVND(item.minPrice))),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "info-item" }, [
                      _c("label", { staticClass: "type-info" }, [
                        _vm._v("Km ít nhất:"),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "value-info" }, [
                        _vm._v(_vm._s(item.kmMinPrice)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "info-item" }, [
                      _c("label", { staticClass: "type-info" }, [
                        _vm._v("Giá mỗi Km:"),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "value-info" }, [
                        _vm._v(_vm._s(_vm.formatVND(item.pricePerKM))),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("i", { staticClass: "el-icon-info button-info" }),
                ]
              ),
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            visible: _vm.dialogVisible,
            width: "560px",
            title: _vm.dialogStatus == "edit" ? "Cập nhật" : "Thêm",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                "label-position": "top",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Loại" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { disabled: "", placeholder: "" },
                              model: {
                                value: _vm.formData.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "type", $$v)
                                },
                                expression: "formData.type",
                              },
                            },
                            _vm._l(_vm.ORDER_TYPE, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item, value: index },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Giá thấp nhất", prop: "minPrice" } },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formData.minPrice,
                                expression: "formData.minPrice",
                              },
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: {
                                  alias: "decimal",
                                  groupSeparator: ".",
                                  rightAlign: false,
                                  autoGroup: true,
                                  repeat: 20,
                                  autoUnmask: true,
                                  allowMinus: false,
                                },
                                expression:
                                  "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.formData.minPrice },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.formData,
                                  "minPrice",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Km ít nhất", prop: "kmMinPrice" } },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formData.kmMinPrice,
                                expression: "formData.kmMinPrice",
                              },
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: {
                                  alias: "decimal",
                                  groupSeparator: ".",
                                  rightAlign: false,
                                  autoGroup: true,
                                  repeat: 20,
                                  autoUnmask: true,
                                  allowMinus: false,
                                },
                                expression:
                                  "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.formData.kmMinPrice },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.formData,
                                  "kmMinPrice",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Giá mỗi Km", prop: "pricePerKM" } },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formData.pricePerKM,
                                expression: "formData.pricePerKM",
                              },
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: {
                                  alias: "decimal",
                                  groupSeparator: ".",
                                  rightAlign: false,
                                  autoGroup: true,
                                  repeat: 20,
                                  autoUnmask: true,
                                  allowMinus: false,
                                },
                                expression:
                                  "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.formData.pricePerKM },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.formData,
                                  "pricePerKM",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingButton },
                  on: {
                    click: function ($event) {
                      _vm.dialogStatus == "edit"
                        ? _vm.editData()
                        : _vm.createData()
                    },
                  },
                },
                [_vm._v("Đồng ý")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }