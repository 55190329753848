var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-editor-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          !_vm.$store.state.user.info.area
            ? _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  staticStyle: { width: "140px" },
                  attrs: { placeholder: "Chọn khu vực" },
                  model: {
                    value: _vm.areaId,
                    callback: function ($$v) {
                      _vm.areaId = $$v
                    },
                    expression: "areaId",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "Tất cả", value: null } }),
                  _vm._v(" "),
                  _vm._l(_vm.areas, function (area) {
                    return _c("el-option", {
                      key: area.id,
                      attrs: { label: area.name, value: area.id },
                    })
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("panel-group", { attrs: { "area-id": _vm.areaId } }),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 32 } },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [_c("CustomerChart", { attrs: { "area-id": _vm.areaId } })],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [_c("FoodChart", { attrs: { "area-id": _vm.areaId } })],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [_c("DeliveryChart", { attrs: { "area-id": _vm.areaId } })],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [_c("TransportChart", { attrs: { "area-id": _vm.areaId } })],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 32 } },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [_c("TopStore", { attrs: { "area-id": _vm.areaId } })],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }