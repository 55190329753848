var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "" } },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "el-input",
        {
          staticClass: "input-with-select",
          attrs: {
            label: "Tên ghi chú hiển thị",
            placeholder: "VD: Bấm xem các loại",
          },
          model: {
            value: _vm.foodVariationGroupName,
            callback: function ($$v) {
              _vm.foodVariationGroupName = $$v
            },
            expression: "foodVariationGroupName",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { slot: "append", plain: "", type: "primary" },
              on: { click: _vm.handleUpdateGroupName },
              slot: "append",
            },
            [_vm._v("Lưu")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("p", [_vm._v("Thêm size, topping (nếu có)")]),
      _vm._v(" "),
      _c(
        "el-table",
        { attrs: { data: _vm.foodVariations, border: "", height: "40vh" } },
        [
          _c("el-table-column", {
            attrs: { label: "Tên gọi" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v("\n        " + _vm._s(row.name) + "\n      ")]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Đơn giá", width: "130" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.formatMoney(row.price)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Vị trí", width: "60", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v("\n        " + _vm._s(row.position) + "\n      "),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: 120, align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-button", {
                      attrs: { type: "primary", icon: "el-icon-edit" },
                      on: {
                        click: function ($event) {
                          return _vm.handleEdit(row)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("el-button", {
                      attrs: { type: "danger", icon: "el-icon-delete" },
                      on: {
                        click: function ($event) {
                          return _vm.handleDelete(row)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticStyle: { "margin-top": "10px" },
          attrs: { type: "primary", size: "small" },
          on: { click: _vm.handleAddVariant },
        },
        [_vm._v("Thêm dòng")]
      ),
      _vm._v(" "),
      _c("pagination", {
        staticStyle: { padding: "0" },
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.fetchVariations,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            title:
              _vm.dialogStatus == "add" ? "Thêm biến thể" : "Cập nhật biến thể",
            visible: _vm.visible,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "80px",
                size: "normal",
                "label-position": "top",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Tên gọi", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "Thứ tự", prop: "position" } },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.position,
                        expression: "form.position",
                      },
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: {
                          alias: "decimal",
                          groupSeparator: ".",
                          rightAlign: false,
                          autoGroup: true,
                          repeat: 20,
                          autoUnmask: true,
                          allowMinus: false,
                        },
                        expression:
                          "{\n            alias: 'decimal',\n            groupSeparator: '.',\n            rightAlign: false,\n            autoGroup: true,\n            repeat: 20,\n            autoUnmask: true,\n            allowMinus: false,\n          }",
                      },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.form.position },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "position", $event.target.value)
                      },
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "Giá", prop: "price" } }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.price,
                      expression: "form.price",
                    },
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: {
                        alias: "decimal",
                        groupSeparator: ".",
                        rightAlign: false,
                        autoGroup: true,
                        repeat: 20,
                        autoUnmask: true,
                        allowMinus: false,
                      },
                      expression:
                        "{\n            alias: 'decimal',\n            groupSeparator: '.',\n            rightAlign: false,\n            autoGroup: true,\n            repeat: 20,\n            autoUnmask: true,\n            allowMinus: false,\n          }",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.form.price },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "price", $event.target.value)
                    },
                  },
                }),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.visible = false
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogStatus == "add"
                        ? _vm.handleSubmit()
                        : _vm.handleSubmitEdit()
                    },
                  },
                },
                [_vm._v("Lưu")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { attrs: { for: "" } }, [
      _c("b", [_vm._v("Tên ghi chú hiển thị")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }