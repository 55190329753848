var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          !_vm.$store.state.user.info.area
            ? _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  staticStyle: { width: "140px" },
                  attrs: { placeholder: "Chọn khu vực" },
                  on: { change: _vm.getData },
                  model: {
                    value: _vm.listQuery.areaId,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "areaId", $$v)
                    },
                    expression: "listQuery.areaId",
                  },
                },
                _vm._l(_vm.areas, function (area) {
                  return _c("el-option", {
                    key: area.id,
                    attrs: { label: area.name, value: area.id },
                  })
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, border: "", stripe: "" } },
        [
          _c("el-table-column", { attrs: { type: "index" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Loại", prop: "param" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(_vm._s(_vm.PARAMS[row.type]))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Hoa hồng", prop: "value" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(_vm._s(row.value) + "%")]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Hành động" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(row)
                          },
                        },
                      },
                      [_vm._v("Cập nhật")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            visible: _vm.dialogVisible,
            width: "500px",
            title: _vm.dialogStatus == "edit" ? "Cập nhật" : "Thêm",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                "label-position": "top",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Loại", prop: "type" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.formData.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "type", $$v)
                                },
                                expression: "formData.type",
                              },
                            },
                            _vm._l(_vm.PARAMS, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item, value: index },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Hoa hồng", prop: "value" } },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formData.value,
                                expression: "formData.value",
                              },
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: {
                                  alias: "decimal",
                                  groupSeparator: ".",
                                  rightAlign: false,
                                  autoGroup: true,
                                  repeat: 20,
                                  autoUnmask: true,
                                  allowMinus: false,
                                },
                                expression:
                                  "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.formData.value },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.formData,
                                  "value",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingButton },
                  on: {
                    click: function ($event) {
                      _vm.dialogStatus == "edit"
                        ? _vm.editData()
                        : _vm.createData()
                    },
                  },
                },
                [_vm._v("Đồng ý")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }