var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "promotion-dialog" } },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title:
              _vm.dialogStatus == "update"
                ? "Cập nhật voucher"
                : "Thêm voucher",
            visible: _vm.dialogVisible,
            width: "1000px",
            top: "15px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading",
                },
              ],
            },
            [
              _c(
                "el-tabs",
                {
                  attrs: { type: "card" },
                  model: {
                    value: _vm.tabName,
                    callback: function ($$v) {
                      _vm.tabName = $$v
                    },
                    expression: "tabName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Thông tin", name: "info" } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "form",
                          attrs: {
                            model: _vm.formData,
                            rules: _vm.rules,
                            "label-width": "80px",
                            "label-position": "top",
                          },
                        },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-row",
                                    { attrs: { gutter: 20 } },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "Code",
                                                prop: "code",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                model: {
                                                  value: _vm.formData.code,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formData,
                                                      "code",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "formData.code",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop: "areaId",
                                                label: "Khu vực",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    placeholder: "",
                                                    clearable: "",
                                                    filterable: "",
                                                    disabled:
                                                      _vm.$store.state.user.info
                                                        .area,
                                                  },
                                                  on: {
                                                    change: function () {
                                                      _vm.fetchStore()
                                                      _vm.formData.storeIds = []
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.formData.areaId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.formData,
                                                        "areaId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formData.areaId",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.areas,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.id,
                                                      attrs: {
                                                        label: item.name,
                                                        value: item.id,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "Ngày bắt đầu",
                                                prop: "start",
                                              },
                                            },
                                            [
                                              _c("el-date-picker", {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  format: "dd/MM/yyyy",
                                                  "value-format": "timestamp",
                                                  type: "date",
                                                  placeholder: "Pick a day",
                                                },
                                                model: {
                                                  value: _vm.formData.start,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formData,
                                                      "start",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "formData.start",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "Ngày kết thúc",
                                                prop: "end",
                                              },
                                            },
                                            [
                                              _c("el-date-picker", {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  format: "dd/MM/yyyy",
                                                  "value-format": "timestamp",
                                                  type: "date",
                                                  placeholder: "Pick a day",
                                                },
                                                model: {
                                                  value: _vm.formData.end,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formData,
                                                      "end",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "formData.end",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "Hình thức giảm",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: { placeholder: "" },
                                                  model: {
                                                    value: _vm.formData.form,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.formData,
                                                        "form",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "formData.form",
                                                  },
                                                },
                                                [
                                                  _c("el-option", {
                                                    attrs: {
                                                      label:
                                                        "Giảm cho tổng đơn hàng",
                                                      value:
                                                        _vm.VoucherFormType
                                                          .Product,
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("el-option", {
                                                    attrs: {
                                                      label:
                                                        "Giảm trừ phí ship",
                                                      value:
                                                        _vm.VoucherFormType
                                                          .Ship,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "Điều kiện áp dụng",
                                                prop: "conditionMinPrice",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-input",
                                                {
                                                  directives: [
                                                    {
                                                      name: "mask-input",
                                                      rawName: "v-mask-input",
                                                      value: {
                                                        alias: "decimal",
                                                        groupSeparator: ".",
                                                        rightAlign: false,
                                                        autoGroup: true,
                                                        repeat: 20,
                                                        autoUnmask: true,
                                                        allowMinus: false,
                                                      },
                                                      expression:
                                                        "{\n                          alias: 'decimal',\n                          groupSeparator: '.',\n                          rightAlign: false,\n                          autoGroup: true,\n                          repeat: 20,\n                          autoUnmask: true,\n                          allowMinus: false\n                        }",
                                                    },
                                                  ],
                                                  attrs: {
                                                    placeholder:
                                                      "0 sẽ bỏ qua điều kiện",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.formData
                                                        .conditionMinPrice,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.formData,
                                                        "conditionMinPrice",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formData.conditionMinPrice",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "template",
                                                    { slot: "append" },
                                                    [
                                                      _vm._v(
                                                        "\n                          đ\n                        "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "Số tiền được giảm",
                                                prop: "discountValue",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-input",
                                                {
                                                  directives: [
                                                    {
                                                      name: "mask-input",
                                                      rawName: "v-mask-input",
                                                      value: {
                                                        alias: "decimal",
                                                        groupSeparator: ".",
                                                        rightAlign: false,
                                                        autoGroup: true,
                                                        repeat: 20,
                                                        autoUnmask: true,
                                                        allowMinus: false,
                                                      },
                                                      expression:
                                                        "{\n                          alias: 'decimal',\n                          groupSeparator: '.',\n                          rightAlign: false,\n                          autoGroup: true,\n                          repeat: 20,\n                          autoUnmask: true,\n                          allowMinus: false\n                        }",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "input-with-select",
                                                  model: {
                                                    value:
                                                      _vm.formData
                                                        .discountValue,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.formData,
                                                        "discountValue",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formData.discountValue",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      staticStyle: {
                                                        width: "150px",
                                                      },
                                                      attrs: {
                                                        slot: "prepend",
                                                        placeholder: "",
                                                      },
                                                      slot: "prepend",
                                                      model: {
                                                        value:
                                                          _vm.formData
                                                            .discountType,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.formData,
                                                            "discountType",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "formData.discountType",
                                                      },
                                                    },
                                                    [
                                                      _c("el-option", {
                                                        attrs: {
                                                          label: "Theo giá",
                                                          value:
                                                            _vm
                                                              .VoucherDiscountType
                                                              .Amount,
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("el-option", {
                                                        attrs: {
                                                          label:
                                                            "Theo phần trăm",
                                                          value:
                                                            _vm
                                                              .VoucherDiscountType
                                                              .Percent,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "template",
                                                    { slot: "append" },
                                                    [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            _vm.formData
                                                              .discountType ==
                                                              _vm
                                                                .VoucherDiscountType
                                                                .Amount
                                                              ? "đ"
                                                              : "%"
                                                          ) +
                                                          "\n                        "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c("p", [
                                            _c("b", [_vm._v("Số lượng")]),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "el-radio",
                                            {
                                              attrs: { label: false },
                                              model: {
                                                value:
                                                  _vm.formData.isLimitQuantity,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "isLimitQuantity",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData.isLimitQuantity",
                                              },
                                            },
                                            [_vm._v("Không giới hạn")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-radio",
                                            {
                                              attrs: { label: true },
                                              model: {
                                                value:
                                                  _vm.formData.isLimitQuantity,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "isLimitQuantity",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData.isLimitQuantity",
                                              },
                                            },
                                            [_vm._v("Giới hạn")]
                                          ),
                                          _vm._v(" "),
                                          _vm.formData.isLimitQuantity
                                            ? _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "10px",
                                                  },
                                                  attrs: { prop: "quantity" },
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.formData.quantity,
                                                        expression:
                                                          "formData.quantity",
                                                      },
                                                      {
                                                        name: "mask",
                                                        rawName: "v-mask",
                                                        value: {
                                                          alias: "decimal",
                                                          groupSeparator: ".",
                                                          rightAlign: false,
                                                          autoGroup: true,
                                                          repeat: 20,
                                                          autoUnmask: true,
                                                          allowMinus: false,
                                                        },
                                                        expression:
                                                          "{\n                          alias: 'decimal',\n                          groupSeparator: '.',\n                          rightAlign: false,\n                          autoGroup: true,\n                          repeat: 20,\n                          autoUnmask: true,\n                          allowMinus: false\n                        }",
                                                      },
                                                    ],
                                                    attrs: { type: "text" },
                                                    domProps: {
                                                      value:
                                                        _vm.formData.quantity,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          _vm.formData,
                                                          "quantity",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "Cửa hàng" } },
                                            [
                                              _c(
                                                "el-radio",
                                                {
                                                  attrs: {
                                                    label:
                                                      _vm.VoucherApplyType.All,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.formData.applyType,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.formData,
                                                        "applyType",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formData.applyType",
                                                  },
                                                },
                                                [_vm._v("Tất cả")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-radio",
                                                {
                                                  attrs: {
                                                    label:
                                                      _vm.VoucherApplyType
                                                        .Select,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.formData.applyType,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.formData,
                                                        "applyType",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formData.applyType",
                                                  },
                                                },
                                                [_vm._v("Chọn cửa hàng")]
                                              ),
                                              _vm._v(" "),
                                              _vm.formData.applyType ==
                                              _vm.VoucherApplyType.Select
                                                ? _c(
                                                    "el-select",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        filterable: "",
                                                        multiple: "",
                                                        size: "medium",
                                                        placeholder: "",
                                                        remote: "",
                                                        "remote-method":
                                                          _vm.fetchStore,
                                                        disabled:
                                                          !_vm.formData
                                                            .areaId &&
                                                          !_vm.$store.state.user
                                                            .info.area,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.formData.storeIds,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.formData,
                                                            "storeIds",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "formData.storeIds",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.stores,
                                                      function (item) {
                                                        return _c("el-option", {
                                                          key: item.id,
                                                          attrs: {
                                                            label: item.name,
                                                            value: item.id,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.dialogStatus == "update"
                    ? _c(
                        "el-tab-pane",
                        { attrs: { label: "Đơn hàng áp dụng", name: "order" } },
                        [
                          _c("list-order", {
                            attrs: {
                              voucherId: _vm.formData.id,
                              showStatusCol: true,
                            },
                            on: {
                              "update:voucherId": function ($event) {
                                return _vm.$set(_vm.formData, "id", $event)
                              },
                              "update:voucher-id": function ($event) {
                                return _vm.$set(_vm.formData, "id", $event)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.submitLoading, type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogStatus == "create"
                        ? _vm.createData()
                        : _vm.updateData()
                    },
                  },
                },
                [_vm._v("Đồng ý")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }