"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.regexp.split");
var _objectSpread2 = _interopRequireDefault(require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.number.constructor");
var _vuex = require("vuex");
var _moment = _interopRequireDefault(require("moment"));
var _s = require("@/plugins/s3");
var _uuid = require("uuid");
var _url = require("@/utils/url");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    pathImage: String,
    width: {
      type: Number,
      default: 360
    },
    height: {
      type: Number,
      default: 180
    },
    disabled: {
      type: Boolean,
      default: false
    },
    prefixFile: {
      type: String,
      default: ""
    },
    sizeLimit: {
      type: Number,
      default: 2
    }
  },
  data: function data() {
    return {
      isProgressing: false,
      percentUpload: 0,
      urlUpload: ""
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["$baseUrl", "$url"])), {}, {
    token: function token() {
      return this.$store.state.user.token;
    },
    computeWidth: function computeWidth() {
      if (isNaN(this.width)) {
        return this.width;
      }
      return "".concat(this.width, "px");
    },
    computeHeight: function computeHeight() {
      if (isNaN(this.height)) {
        return this.height;
      }
      return "".concat(this.height, "px");
    }
  }),
  methods: {
    url: function url(image) {
      return (0, _url.$url)(image);
    },
    handlePreviewImage: function handlePreviewImage() {
      this.$refs.imagePreview.$el.children[0].click();
    },
    onProgress: function onProgress(percent) {
      this.percentUpload = +percent.toFixed(0);
    },
    onError: function onError(err, file, fileList) {
      if (err.message) {
        this.$message.error(err.message);
      }
      this.isProgressing = false;
    },
    emitSuccess: function emitSuccess(url) {
      this.$emit("upload:success", url);
    },
    handleSuccess: function handleSuccess(key) {
      var _this = this;
      var url = "";
      if (!this.prefixFile) {
        url = "".concat(process.env.VUE_APP_CLOUD_FRONT_URL, "/").concat(key);
      } else {
        var folderName = key.split("/").shift();
        var fileName = key.split("/").pop();
        url = "".concat(process.env.VUE_APP_CLOUD_FRONT_URL, "/").concat(folderName, "/converted").concat(fileName);
      }
      setTimeout(function () {
        _this.emitSuccess(url);
        _this.isProgressing = false;
      }, 3 * 1000);
    },
    beforeUpload: function beforeUpload(file) {
      var _this2 = this;
      var acceptTypes = ["png", "jpg", "jpeg"];
      var isJPG = acceptTypes.some(function (type) {
        return file.type.includes(type.toLowerCase());
      });
      if (!isJPG) {
        this.$message.error("Chỉ hỗ trợ định dạng PNG, JPG!");
        return false;
      }
      if (this.sizeLimit) {
        if (file.size > 1024 * 1024 * this.sizeLimit) {
          this.$message.error("\u1EA2nh k\xEDch th\u01B0\u1EDBc t\u1ED1i \u0111a l\xE0 ".concat(this.sizeLimit, "MB!"));
          return false;
        }
      }
      var splitName = file.name.split(".");
      var name = (0, _uuid.v4)();
      var extendName = splitName[splitName.length - 1];
      var key = "".concat(name, "-").concat((0, _moment.default)().unix(), ".").concat(extendName);
      if (this.prefixFile) {
        key = this.prefixFile + "/" + key;
      }
      this.isProgressing = true;
      (0, _s.uploadToS3)(file, key, this.onProgress, function () {
        return _this2.handleSuccess(key);
      }, file.type);
      return false;
    }
  }
};