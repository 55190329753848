var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        id: "driver",
        top: "30px",
        title: _vm.dialogStatus == "add" ? "Thêm tài xế" : "Cập nhật tài xế",
        visible: _vm.dialogVisible,
        width: _vm.dialogWith,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dialogLoading,
              expression: "dialogLoading",
            },
          ],
        },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "card" },
              model: {
                value: _vm.tabName,
                callback: function ($$v) {
                  _vm.tabName = $$v
                },
                expression: "tabName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "Thông tin", name: "info" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "dataForm",
                      attrs: {
                        disabled: _vm.dialogDisabled,
                        rules: _vm.rules,
                        model: _vm.tempData,
                        "label-position": "left",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Họ tên", prop: "name" } },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.tempData.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.tempData, "name", $$v)
                                      },
                                      expression: "tempData.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Số điện thoại",
                                    prop: "phone",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.tempData.phone,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.tempData, "phone", $$v)
                                      },
                                      expression: "tempData.phone",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Nick name",
                                    prop: "nickname",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.tempData.nickname,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.tempData, "nickname", $$v)
                                      },
                                      expression: "tempData.nickname",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Ngày sinh",
                                    prop: "dayOfBirth",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      format: "dd/MM/yyyy",
                                      "value-format": "dd/MM/yyyy",
                                      type: "date",
                                      placeholder: "Chọn ngày sinh",
                                    },
                                    model: {
                                      value: _vm.tempData.dayOfBirth,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tempData,
                                          "dayOfBirth",
                                          $$v
                                        )
                                      },
                                      expression: "tempData.dayOfBirth",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Tài khoản",
                                    prop: "username",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.tempData.username,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.tempData, "username", $$v)
                                      },
                                      expression: "tempData.username",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.dialogStatus == "add"
                            ? _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Mật khẩu",
                                        prop: "password",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.tempData.password,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.tempData,
                                              "password",
                                              $$v
                                            )
                                          },
                                          expression: "tempData.password",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Biển số",
                                    prop: "licensePlate",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.tempData.licensePlate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tempData,
                                          "licensePlate",
                                          $$v
                                        )
                                      },
                                      expression: "tempData.licensePlate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "areaId", label: "Khu vực" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: "",
                                        clearable: "",
                                        filterable: "",
                                        disabled:
                                          _vm.$store.state.user.info.area,
                                      },
                                      model: {
                                        value: _vm.tempData.areaId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.tempData, "areaId", $$v)
                                        },
                                        expression: "tempData.areaId",
                                      },
                                    },
                                    _vm._l(_vm.areas, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.dialogStatus != "add"
                            ? _c(
                                "el-col",
                                { staticStyle: { "margin-bottom": "10px" } },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { "font-weight": "bold" } },
                                    [
                                      _vm._v(
                                        "Số dư: " +
                                          _vm._s(
                                            _vm.formatCurrency(
                                              _vm.tempData.balance
                                            )
                                          )
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _c(
                                "el-upload",
                                {
                                  attrs: {
                                    disabled: _vm.dialogDisabled,
                                    name: "image",
                                    drag: "",
                                    "before-upload": _vm.beforeUpload,
                                    action:
                                      _vm.$uploadUrl + "/driver/avatar/upload",
                                    "on-success": _vm.handleAvatarSuccess,
                                    "show-file-list": false,
                                    headers: {
                                      token: _vm.token,
                                      version: _vm.$version,
                                    },
                                    "on-error": _vm.errorUpload,
                                  },
                                },
                                [
                                  !_vm.tempData.avatar
                                    ? [
                                        _c("i", {
                                          staticClass: "el-icon-upload",
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "el-upload__text" },
                                          [
                                            _vm._v(
                                              "\n                    Kéo thả vào đây hoặc\n                    "
                                            ),
                                            _c("em", [
                                              _vm._v("click vào để upload"),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    : _c("img", {
                                        staticClass: "avatar",
                                        attrs: {
                                          src: _vm.$url(_vm.tempData.avatar),
                                          alt: "",
                                        },
                                      }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.dialogStatus != "add"
                ? _c(
                    "el-tab-pane",
                    {
                      attrs: {
                        name: "order-history",
                        label: "Lịch sử đơn hàng",
                      },
                    },
                    [
                      _c("OrderHistory", {
                        attrs: { driverId: _vm.tempData.id },
                        on: {
                          "update:driverId": function ($event) {
                            return _vm.$set(_vm.tempData, "id", $event)
                          },
                          "update:driver-id": function ($event) {
                            return _vm.$set(_vm.tempData, "id", $event)
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus != "add"
                ? _c(
                    "el-tab-pane",
                    {
                      attrs: { name: "pay-history", label: "Lịch sử nạp/rút" },
                    },
                    [
                      _c("PayHistory", {
                        attrs: { driverId: _vm.tempData.id },
                        on: {
                          "update:driverId": function ($event) {
                            return _vm.$set(_vm.tempData, "id", $event)
                          },
                          "update:driver-id": function ($event) {
                            return _vm.$set(_vm.tempData, "id", $event)
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogStatus == "edit"
                ? _c(
                    "el-tab-pane",
                    {
                      attrs: {
                        name: "transaction",
                        label: "Lịch sử giao dịch",
                      },
                    },
                    [
                      _c("Transaction", {
                        attrs: { driverId: _vm.tempData.id },
                        on: {
                          "update:driverId": function ($event) {
                            return _vm.$set(_vm.tempData, "id", $event)
                          },
                          "update:driver-id": function ($event) {
                            return _vm.$set(_vm.tempData, "id", $event)
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _vm.tabName == "info" && _vm.dialogStatus == "edit"
            ? _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "success",
                        size: "mini",
                        loading: _vm.loadingButton,
                      },
                      on: { click: _vm.handleReset },
                    },
                    [_vm._v("Reset")]
                  ),
                  _vm._v(" "),
                  !_vm.tempData.isBlock
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "danger",
                            loading: _vm.loadingButton,
                            size: "mini",
                          },
                          on: { click: _vm.handleBlock },
                        },
                        [_vm._v("Khóa")]
                      )
                    : _c(
                        "el-button",
                        {
                          attrs: {
                            type: "warning",
                            loading: _vm.loadingButton,
                            size: "mini",
                          },
                          on: { click: _vm.handleUnBlock },
                        },
                        [_vm._v("Mở khóa")]
                      ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "danger",
                        loading: _vm.loadingButton,
                        size: "mini",
                      },
                      on: { click: _vm.handleDelete },
                    },
                    [_vm._v("Xoá")]
                  ),
                ],
                1
              )
            : _c("div"),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
              _vm._v(" "),
              _vm.tabName == "info"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.loadingButton },
                      on: {
                        click: function ($event) {
                          _vm.dialogStatus == "edit"
                            ? _vm.editData()
                            : _vm.createData()
                        },
                      },
                    },
                    [_vm._v("Đồng ý")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }