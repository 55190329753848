"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.voucherApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var voucherApi = exports.voucherApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/voucher",
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/voucher",
      data: data,
      method: "post"
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/voucher/".concat(id),
      method: "patch",
      data: data
    });
  },
  delete: function _delete(id) {
    return (0, _request.default)({
      url: "/voucher/".concat(id),
      method: "delete"
    });
  }
};