"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = create;
exports.findAll = findAll;
var _request = _interopRequireDefault(require("@/utils/request"));
function findAll(listQuery) {
  return (0, _request.default)({
    url: '/deposit',
    method: 'get',
    params: listQuery
  });
}
function create(data) {
  return (0, _request.default)({
    url: '/deposit',
    method: 'post',
    data: data
  });
}