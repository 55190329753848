"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _MultipleImageS = _interopRequireDefault(require("@/components/Upload/MultipleImageS3.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    MultipleImageS3: _MultipleImageS.default
  },
  props: ["form"],
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["token"])),
  data: function data() {
    return {
      fileList: [],
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA
    };
  },
  methods: {
    uploadListSuccess: function uploadListSuccess(response, file, fileList) {
      this.fileList.push(response);
    },
    handleRemove: function handleRemove(file) {
      this.fileList = this.fileList.filter(function (item) {
        return item != file;
      });
    },
    handleError: function handleError(response, file, fileList) {
      var message = JSON.parse(response.message);
      this.$notify.error({
        message: message.message,
        title: "Thông báo"
      });
    },
    handleExceed: function handleExceed(file, fileList) {
      this.$notify.error({
        message: "Số lượng hình đã tối đa. Nếu muốn upload hình mới, vui lòng xoá hình cũ để có thể upload tiếp",
        title: "Thông báo"
      });
    }
  }
};