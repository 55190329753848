"use strict";

var _interopRequireWildcard = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.number.constructor");
var order = _interopRequireWildcard(require("@/api/order/transport"));
var driver = _interopRequireWildcard(require("@/api/driver"));
var _date = require("@/utils/date");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _format = require("@/utils/format");
var _enum = require("@/utils/enum");
var _CustomerDialog = _interopRequireDefault(require("@/views/customer/components/CustomerDialog"));
var _DriverDialog = _interopRequireDefault(require("@/views/driver/components/DriverDialog"));
var _moment = _interopRequireDefault(require("moment"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "OrderTransport",
  components: {
    Pagination: _Pagination.default,
    CustomerDialog: _CustomerDialog.default,
    DriverDialog: _DriverDialog.default
  },
  props: {
    driverId: {
      type: Number,
      default: null
    },
    showStatusCol: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      date: [(0, _moment.default)().subtract(7, "days").format("YYYY-MM-DD"), (0, _moment.default)().format("YYYY-MM-DD")],
      totalRevenue: 0,
      totalIncome: 0,
      totalMoney: 0,
      ORDER_DELIVERY_STATUS: _enum.ORDER_DELIVERY_STATUS,
      ORDER_ACTION: _enum.ORDER_ACTION,
      listLoading: false,
      total: 0,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      listQuery: {
        page: 1,
        limit: 10,
        search: "",
        status: null,
        isBlock: null
      },
      tableData: [],
      tagOptions: {
        WAITING: {
          type: "warning",
          effect: "plain"
        },
        DELIVERING: {
          type: "",
          effect: "plain"
        },
        COMPLETE: {
          type: "success",
          effect: "dark"
        },
        CANCEL: {
          type: "danger",
          effect: "dark"
        }
      },
      datePicker: {
        minDate: null,
        maxDate: null
      },
      pickerOptions: {
        disabledDate: this.disabledDueDate,
        onPick: this.handleChangeDatePicker
      }
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    areas: function areas(state) {
      return state.area.list;
    }
  })),
  created: function created() {
    if (!this.driverId) {
      this.getTableData();
    } else {
      this.getTableDataByDriverId();
    }
    this.$store.dispatch("area/fetchList");
  },
  watch: {
    driverId: function driverId() {
      this.getTableDataByDriverId();
    }
  },
  methods: {
    viewCustomer: function viewCustomer(customer) {
      this.$refs["customer-dialog"].handleEdit(customer);
    },
    viewDriver: function viewDriver(driver) {
      this.$refs["driver-dialog"].handleEdit(driver);
    },
    disabledDueDate: function disabledDueDate(time) {
      if (this.datePicker.minDate) {
        var past60days = (0, _moment.default)(this.datePicker.minDate).subtract(60, "days").valueOf();
        var next60days = (0, _moment.default)(this.datePicker.minDate).add(60, "days").valueOf();
        return time.getTime() < past60days || time.getTime() > next60days;
      }
      return false;
    },
    handleChangeDatePicker: function handleChangeDatePicker(date) {
      this.datePicker = date;
    },
    getTableDataByDriverId: function getTableDataByDriverId() {
      var _this = this;
      driver.orderDelivery(this.driverId, this.listQuery).then(function (res) {
        _this.tableData = res.data.data;
        _this.total = res.data.total;
      }).finally(function () {
        _this.listLoading = false;
      });
    },
    formatNumberVN: function formatNumberVN(n) {
      return (0, _format.formatNumberVN)(n);
    },
    formatCurrency: function formatCurrency(n) {
      return (0, _format.formatCurrency)(n);
    },
    changeStatus: function changeStatus(select, row) {
      var _this2 = this;
      this.$confirm("B\u1EA1n ch\u1EAFc ch\u1EAFn mu\u1ED1n thay \u0111\u1ED5i tr\u1EA1ng th\xE1i th\xE0nh ".concat(_enum.ORDER_ACTION[select].toUpperCase()), "Cảnh báo", {
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Không",
        type: "warning"
      }).then(function () {
        _this2.listLoading = true;
        var data = {
          orderTransport: {
            status: select
          }
        };
        order.update(row.id, data).then(function () {
          _this2.$notify({
            type: "success",
            message: "Thành công"
          });
          _this2.getTableData();
        }).finally(function () {
          _this2.listLoading = false;
        });
      });
    },
    getTotalMoney: function getTotalMoney() {
      var _this3 = this;
      var listQuery = {
        from: this.listQuery.from,
        to: this.listQuery.to
      };
      var data = [];
      order.findAll(listQuery).then(function (res) {
        data = res.data.data;
        var total = 0;
        data.forEach(function (element) {
          if (element.status == "COMPLETE") total += element.moneyFinal - element.moneyIncome;
        });
        _this3.totalMoney = total;
      });
    },
    getSummary: function getSummary() {
      var _this4 = this;
      order.getSummary(this.listQuery).then(function (res) {
        _this4.totalRevenue = res.data.totalRevenue;
        _this4.totalIncome = res.data.totalIncome;
      });
    },
    getTableData: function getTableData() {
      var _this5 = this;
      this.listLoading = true;
      this.listQuery.from = this.date[0];
      this.listQuery.to = this.date[1];
      this.getSummary();
      order.findAll(this.listQuery).then(function (res) {
        _this5.tableData = res.data.data;
        _this5.total = res.data.total;
      }).finally(function () {
        _this5.listLoading = false;
      });
    },
    formatDateTime: function formatDateTime(timestamp) {
      return (0, _date.formatDateTime)(timestamp);
    }
  }
};