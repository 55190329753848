"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = create;
exports.findAll = findAll;
exports.remove = remove;
exports.update = update;
var _request = _interopRequireDefault(require("@/utils/request"));
function findAll(params) {
  return (0, _request.default)({
    url: "/homeBanner",
    params: params
  });
}
function create(data) {
  return (0, _request.default)({
    url: "/homeBanner",
    method: "post",
    data: data
  });
}
function update(id, data) {
  return (0, _request.default)({
    url: "/homeBanner/".concat(id),
    method: "patch",
    data: data
  });
}
function remove(id) {
  return (0, _request.default)({
    url: "/homeBanner/".concat(id),
    method: "delete"
  });
}