"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
var _interopRequireWildcard2 = _interopRequireDefault(require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
_vue.default.use(_vueRouter.default);

/* Layout */

/* Router Modules */

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = exports.constantRoutes = [{
  path: "/redirect",
  component: _layout.default,
  hidden: true,
  children: [{
    path: "/redirect/:path*",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/redirect/index"));
      });
    }
  }]
}, {
  path: "/login",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/index"));
    });
  },
  hidden: true
},
// {
//   path: "/report",
//   component: () => import("@/views/report/index"),
//   hidden: true
// },
{
  path: "/policy",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/policy/index"));
    });
  },
  hidden: true
}, {
  path: "/auth-redirect",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/auth-redirect"));
    });
  },
  hidden: true
}, {
  path: "/404",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/404"));
    });
  },
  hidden: true
}, {
  path: "/401",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/401"));
    });
  },
  hidden: true
}, {
  path: "/",
  redirect: "/dashboard",
  hidden: true
}, {
  path: "/dashboard",
  component: _layout.default,
  redirect: "/dashboard/index",
  permission: true,
  meta: {
    title: "Dashboard",
    icon: "dashboard",
    permissionName: ["Admin", "Staff"]
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/dashboard/index"));
      });
    },
    name: "Dashboard",
    meta: {
      title: "Dashboard",
      permissionName: ["Admin", "Staff"]
    }
  }
  // {
  //   path: 'report',
  //   component: () => import('@/views/dashboard/report'),
  //   name: 'Report',
  //   meta: {
  //     title: 'Báo cáo',

  //   }
  // },
  ]
}, {
  path: "/store",
  component: _layout.default,
  redirect: "/store/index",
  permission: true,
  meta: {
    title: "Cửa hàng",
    icon: "example",
    permissionName: ["Admin", "Staff"]
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/store/index"));
      });
    },
    name: "Store",
    meta: {
      title: "Danh sách cửa hàng",
      permissionName: ["Admin", "Staff"]
    }
  }]
}, {
  path: "/customer",
  component: _layout.default,
  redirect: "/customer/index",
  permission: true,
  meta: {
    title: "Khách hàng",
    icon: "peoples",
    permissionName: ["Admin", "Staff"]
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/customer/index"));
      });
    },
    name: "Customer",
    meta: {
      title: "Danh sách khách hàng",
      permissionName: ["Admin", "Staff"]
    }
  }]
}, {
  path: "/order",
  component: _layout.default,
  redirect: "/customer/food",
  permission: true,
  meta: {
    title: "Đơn hàng",
    icon: "skill",
    permissionName: ["Admin", "Staff"]
  },
  children: [{
    path: "food",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order/food/index"));
      });
    },
    name: "Food",
    meta: {
      title: "Thức ăn",
      permissionName: ["Admin", "Staff"]
    }
  }, {
    path: "delivery",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order/delivery/index"));
      });
    },
    name: "Delivery",
    meta: {
      title: "Giao hàng",
      permissionName: ["Admin", "Staff"]
    }
  }, {
    path: "transport",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order/transport/index"));
      });
    },
    name: "Transport",
    meta: {
      title: "Di chuyển",
      permissionName: ["Admin", "Staff"]
    }
  }]
}, {
  path: "/voucher",
  component: _layout.default,
  redirect: "/voucher/index",
  permission: true,
  meta: {
    title: "Voucher",
    icon: "tag",
    permissionName: ["Admin", "Staff"]
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/promotion/index"));
      });
    },
    name: "Voucher",
    meta: {
      title: "Danh sách voucher",
      permissionName: ["Admin", "Staff"]
    }
  }]
}, {
  path: "/drvier",
  component: _layout.default,
  redirect: "/driver/index",
  permission: true,
  meta: {
    title: "Tài xế",
    icon: "car-fill",
    permissionName: ["Admin", "Staff"]
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/driver/index"));
      });
    },
    name: "Driver",
    meta: {
      title: "Danh sách tài xế",
      permissionName: ["Admin", "Staff"]
    }
  }]
}, {
  path: "/pay",
  component: _layout.default,
  redirect: "/pay/deposit",
  meta: {
    title: "Nạp/Rút",
    icon: "bank-fill",
    permissionName: ["Admin", "Staff"]
  },
  permission: true,
  children: [{
    path: "deposit",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/deposit/index"));
      });
    },
    name: "Deposit",
    meta: {
      title: "Nạp tiền",
      permissionName: ["Admin", "Staff"]
    }
  }, {
    path: "withdraw",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/withdraw/index"));
      });
    },
    name: "Withdraw",
    meta: {
      title: "Rút tiền",
      permissionName: ["Admin", "Staff"]
    }
  }]
}, {
  path: "/config",
  component: _layout.default,
  redirect: "/config/ship",
  meta: {
    title: "Cấu hình",
    icon: "setting-fill",
    permissionName: ["Admin", "Staff"]
  },
  permission: true,
  children: [{
    path: "ship",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/config/order-type"));
      });
    },
    name: "Ship",
    meta: {
      title: "Phí ship",
      permissionName: ["Admin", "Staff"]
    }
  }, {
    path: "encourage",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/config/encourage"));
      });
    },
    name: "Encourage",
    meta: {
      title: "Tiền khích lệ",
      permissionName: ["Admin", "Staff"]
    }
  }, {
    path: "commission",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/config/commission"));
      });
    },
    name: "Commission",
    meta: {
      title: "Hoa hồng",
      permissionName: ["Admin", "Staff"]
    }
  }, {
    path: "config",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/config/configuration"));
      });
    },
    name: "Configuration",
    meta: {
      title: "Chung",
      permissionName: ["Admin", "Staff"]
    }
  }, {
    path: "config-order",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/config/configurationOrder"));
      });
    },
    name: "ConfigurationOrder",
    meta: {
      title: "Nhận đơn",
      permissionName: ["Admin", "Staff"]
    }
  }]
}, {
  path: "/region",
  component: _layout.default,
  redirect: "/region/index",
  permission: true,
  meta: {
    title: "Khu vực",
    icon: "guide",
    permissionName: ["Admin"]
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/region/index"));
      });
    },
    name: "Region",
    meta: {
      title: "Danh sách khu vực",
      permissionName: ["Admin"]
    }
  }]
}, {
  path: "/content",
  component: _layout.default,
  redirect: "/content/index",
  permission: true,
  meta: {
    title: "Nội dung",
    icon: "guide",
    permissionName: ["Admin", "Staff"]
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/content/index"));
      });
    },
    name: "Content",
    meta: {
      title: "Danh sách nội dung",
      permissionName: ["Admin", "Staff"]
    }
  }]
}, {
  path: "/banner",
  component: _layout.default,
  redirect: "/banner/index",
  permission: true,
  meta: {
    title: "Banner",
    icon: "guide",
    permissionName: ["Admin", "Staff"]
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/bannerHome"));
      });
    },
    name: "Banner",
    meta: {
      title: "Banner quán trang chủ",
      permissionName: ["Admin", "Staff"]
    }
  }, {
    path: "notification",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/banner"));
      });
    },
    name: "Banner",
    meta: {
      title: "Banner đầu trang chủ",
      permissionName: ["Admin", "Staff"]
    }
  }, {
    path: "store",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/banner-store"));
      });
    },
    name: "BannerStore",
    meta: {
      title: "Banner cửa hàng",
      permissionName: ["Admin", "Staff"]
    }
  }]
}, {
  path: "/notification",
  component: _layout.default,
  redirect: "/notification/index",
  permission: true,
  meta: {
    title: "Thông báo",
    icon: "notification-fill",
    permissionName: ["Admin", "Staff"]
  },
  children: [{
    path: "customer-notification",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/customer-notification/index"));
      });
    },
    name: "Notification",
    meta: {
      title: "Thông báo khách hàng",
      permissionName: ["Admin", "Staff"]
    }
  }, {
    path: "driver-notification",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/driver-notification/index"));
      });
    },
    name: "Notification",
    meta: {
      title: "Thông báo tài xế",
      permissionName: ["Admin", "Staff"]
    }
  }]
}, {
  path: "/profile",
  component: _layout.default,
  redirect: "/profile/index",
  permission: true,
  meta: {
    title: "Cá nhân",
    icon: "qq",
    permissionName: ["Admin", "Staff"]
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/profile/index"));
      });
    },
    name: "Profile",
    meta: {
      title: "Trang cá nhân",
      permissionName: ["Admin", "Staff"]
    }
  }]
}, {
  path: "/staff",
  component: _layout.default,
  redirect: "/staff/index",
  permission: true,
  meta: {
    title: "Nhân viên",
    icon: "user",
    permissionName: ["Admin"]
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/user"));
      });
    },
    name: "staff-index",
    meta: {
      title: "Danh sách nhân viên",
      permissionName: ["Admin"]
    }
  }]
}, {
  path: "/permission",
  component: _layout.default,
  redirect: "/permission/role",
  hidden: true,
  meta: {
    title: "Quyền",
    icon: "guide"
  },
  permission: true,
  children: [{
    path: "role",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/permission/role"));
      });
    },
    name: "permission-role",
    meta: {
      title: "Danh sách quyền"
    }
  }]
}, {
  path: "/forget",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/forget"));
    });
  }
}, {
  path: "*",
  redirect: "/404",
  hidden: true
}];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
var asyncRoutes = exports.asyncRoutes = [
  // 404 page must be placed at the end !!!
  // {
  //   path: '*',
  //   redirect: '/404',
  //   hidden: true
  // }
];
var createRouter = function createRouter() {
  return new _vueRouter.default({
    mode: "history",
    // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = exports.default = router;