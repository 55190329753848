var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            top: "30px",
            title:
              _vm.dialogStatus == "add" ? "Thêm khu vực" : "Cập nhật khu vực",
            visible: _vm.dialogVisible,
            width: _vm.dialogWidth,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.tempData,
                "label-position": "top",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Tên khu vực", prop: "name" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.tempData.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.tempData, "name", $$v)
                              },
                              expression: "tempData.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Thứ tự", prop: "position" } },
                        [
                          _c("el-input", {
                            attrs: { type: "number" },
                            model: {
                              value: _vm.tempData.position,
                              callback: function ($$v) {
                                _vm.$set(_vm.tempData, "position", $$v)
                              },
                              expression: "tempData.position",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Số zalo", prop: "zalo" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.tempData.zalo,
                              callback: function ($$v) {
                                _vm.$set(_vm.tempData, "zalo", $$v)
                              },
                              expression: "tempData.zalo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Từ khóa tìm kiếm khu vực",
                            prop: "searchKeyword",
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.tempData.searchKeyword,
                              callback: function ($$v) {
                                _vm.$set(_vm.tempData, "searchKeyword", $$v)
                              },
                              expression: "tempData.searchKeyword",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingButton },
                  on: {
                    click: function ($event) {
                      _vm.dialogStatus == "edit"
                        ? _vm.editData()
                        : _vm.createData()
                    },
                  },
                },
                [_vm._v("Đồng ý")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }