var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", attrs: { id: "store" } },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "300px" },
            attrs: {
              "prefix-icon": "el-icon-search",
              placeholder: "Tìm tên cửa hàng",
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.getTableData($event)
              },
            },
            model: {
              value: _vm.listQuery.search,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "search", $$v)
              },
              expression: "listQuery.search",
            },
          }),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "140px" },
              attrs: { placeholder: "Chọn cửa hàng" },
              on: { change: _vm.getTableData },
              model: {
                value: _vm.listQuery.type,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "type", $$v)
                },
                expression: "listQuery.type",
              },
            },
            [
              _c("el-option", { attrs: { label: "Tất cả", value: null } }),
              _vm._v(" "),
              _vm._l(_vm.STORE_TYPE, function (type, index) {
                return _c("el-option", {
                  key: index,
                  attrs: { label: type, value: index },
                })
              }),
            ],
            2
          ),
          _vm._v(" "),
          !_vm.$store.state.user.info.area
            ? _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  staticStyle: { width: "140px" },
                  attrs: { placeholder: "Chọn khu vực" },
                  on: { change: _vm.getTableData },
                  model: {
                    value: _vm.listQuery.areaId,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "areaId", $$v)
                    },
                    expression: "listQuery.areaId",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "Tất cả", value: null } }),
                  _vm._v(" "),
                  _vm._l(_vm.areas, function (area) {
                    return _c("el-option", {
                      key: area.id,
                      attrs: { label: area.name, value: area.id },
                    })
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function ($event) {
                  return _vm.getTableData()
                },
              },
            },
            [_vm._v("Tìm")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-edit" },
              on: {
                click: function ($event) {
                  return _vm.handleAdd()
                },
              },
            },
            [_vm._v("Thêm")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "technician-table",
          attrs: { data: _vm.tableData, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              sortable: "",
              prop: "name",
              label: "Cửa hàng",
              width: "270",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "info-box" }, [
                      _c("div", { staticClass: "info" }, [
                        _c("span", { staticClass: "name" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(row.name) +
                              "\n              "
                          ),
                          _c("div", { staticClass: "isClose" }, [
                            row.isBlock
                              ? _c("span", { staticClass: "close" }, [
                                  _vm._v("(Bị khóa)"),
                                ])
                              : !row.isClosed
                              ? _c("span", { staticClass: "open" }, [
                                  _vm._v("(Mở cửa)"),
                                ])
                              : _c("div", { staticClass: "close" }, [
                                  _vm._v("(Đóng cửa)"),
                                ]),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "image",
                        attrs: { src: _vm.$url(row.thumbnail), alt: "" },
                      }),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { sortable: "", label: "Địa chỉ" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticStyle: { "padding-left": "12px" } }, [
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Địa chỉ: "),
                        ]),
                        _c("span", [_vm._v(_vm._s(row.address))]),
                      ]),
                      _vm._v(" "),
                      row.area
                        ? _c("li", [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Khu vực: "),
                            ]),
                            _c("span", [_vm._v(_vm._s(row.area.name))]),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              sortable: "",
              prop: "openTime",
              label: "Thời gian mở cửa",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v(
                        _vm._s(row.openTime) + "-" + _vm._s(row.closeTime)
                      ),
                    ]),
                    _vm._v(" "),
                    row.openTime2
                      ? _c("div", [
                          _vm._v(
                            "\n          " +
                              _vm._s(row.openTime2) +
                              "-" +
                              _vm._s(row.closeTime2) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { sortable: "", label: "Loại cửa hàng" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("div", [_vm._v(_vm._s(_vm.STORE_TYPE[row.type]))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { sortable: "", label: "Ngày tạo" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v(_vm._s(_vm.formatDateTime(row.dateCreated))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Hiện", width: "50", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-checkbox", {
                      staticClass: "custom-checkbox",
                      attrs: { value: !row.isBlock },
                      on: {
                        change: function ($event) {
                          !row.isBlock
                            ? _vm.handleBlock(row)
                            : _vm.handleUnBlock(row)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Nổi bật", width: "50", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-checkbox", {
                      staticClass: "custom-checkbox",
                      attrs: { value: row.isHighlight },
                      on: {
                        change: function ($event) {
                          return _vm.handleHighlight(row)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Quán khuyến mãi", width: "50", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-checkbox", {
                      staticClass: "custom-checkbox",
                      attrs: { value: row.isPromotion },
                      on: {
                        change: function ($event) {
                          return _vm.handlePromotion(row)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Quán gợi ý", width: "50", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-checkbox", {
                      staticClass: "custom-checkbox",
                      attrs: { value: row.isSuggest },
                      on: {
                        change: function ($event) {
                          return _vm.handleSuggest(row)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "Hành động",
              width: "250",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(row)
                          },
                        },
                      },
                      [_vm._v("Chi tiết")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(row)
                          },
                        },
                      },
                      [_vm._v("Xóa quán")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getTableData,
        },
      }),
      _vm._v(" "),
      _c("store-dialog", {
        ref: "dialog",
        on: {
          "create:success": _vm.getTableData,
          "update:success": _vm.getTableData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }