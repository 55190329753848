"use strict";

var _interopRequireWildcard = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _PanelGroup = _interopRequireDefault(require("./components/PanelGroup"));
var dashboard = _interopRequireWildcard(require("@/api/dashboard"));
var _date = require("@/utils/date");
var _Top5Driver = _interopRequireDefault(require("./components/rank/Top5Driver"));
var _Top5Customer = _interopRequireDefault(require("./components/rank/Top5Customer"));
var _Customer = _interopRequireDefault(require("./components/chart/Customer"));
var _OrderFood = _interopRequireDefault(require("./components/chart/OrderFood"));
var _OrderDelivery = _interopRequireDefault(require("./components/chart/OrderDelivery"));
var _OrderTransport = _interopRequireDefault(require("./components/chart/OrderTransport"));
var _vuex = require("vuex");
var _TopStore = _interopRequireDefault(require("./components/rank/TopStore"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "DashboardAdmin",
  components: {
    PanelGroup: _PanelGroup.default,
    Top5Driver: _Top5Driver.default,
    Top5Customer: _Top5Customer.default,
    CustomerChart: _Customer.default,
    FoodChart: _OrderFood.default,
    DeliveryChart: _OrderDelivery.default,
    TransportChart: _OrderTransport.default,
    TopStore: _TopStore.default
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    areas: function areas(state) {
      return state.area.list;
    }
  })),
  created: function created() {
    this.$store.dispatch("area/fetchList");
  },
  data: function data() {
    return {
      patients: [],
      babyKicks: [],
      top5BabyKick: [],
      areaId: null
    };
  },
  methods: {}
};