import { render, staticRenderFns } from "./OrderDelivery.vue?vue&type=template&id=2766ab70&scoped=true"
import script from "./OrderDelivery.vue?vue&type=script&lang=js"
export * from "./OrderDelivery.vue?vue&type=script&lang=js"
import style0 from "./OrderDelivery.vue?vue&type=style&index=0&id=2766ab70&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2766ab70",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/mac/Documents/project/050/050admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2766ab70')) {
      api.createRecord('2766ab70', component.options)
    } else {
      api.reload('2766ab70', component.options)
    }
    module.hot.accept("./OrderDelivery.vue?vue&type=template&id=2766ab70&scoped=true", function () {
      api.rerender('2766ab70', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/customer/components/OrderDelivery.vue"
export default component.exports