var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12, offset: 6, xs: { span: 24, offset: 0 } } },
            [_c("div", { domProps: { innerHTML: _vm._s(_vm.content) } })]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-container text-center" }, [
      _c("img", {
        staticStyle: { "margin-bottom": "15px" },
        attrs: {
          src: require("@/assets/images/logo.png"),
          width: "150px",
          height: "150px",
          alt: "",
        },
      }),
      _vm._v(" "),
      _c("h1", [_vm._v("Shipper Shipping")]),
      _vm._v(" "),
      _c("h2", { staticClass: "text-center text-bold" }, [
        _vm._v("Chính sách bảo mật"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }