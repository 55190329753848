"use strict";

var _interopRequireWildcard = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _objectSpread2 = _interopRequireDefault(require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var user = _interopRequireWildcard(require("@/api/user"));
var _role = require("@/api/role");
var _validate = require("@/utils/validate");
var _SingleImageS = _interopRequireDefault(require("@/components/Upload/SingleImageS3.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    SingleImageS3: _SingleImageS.default
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["token"])), (0, _vuex.mapState)({
    areas: function areas(state) {
      return state.area.list;
    }
  })),
  props: {
    dialogDisabled: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    var validateEmail = function validateEmail(rule, value, callback) {
      if (!value) {
        callback(new Error("Vui lòng nhập email"));
      } else if (!(0, _validate.validEmail)(value)) {
        callback(new Error("Email không hợp lệ"));
      } else {
        callback();
      }
    };
    return {
      dialogLoading: false,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      tempData: {},
      avatar: "",
      dialogStatus: "",
      dialogVisible: false,
      loadingButton: false,
      roles: [],
      roleId: null,
      rules: {
        name: {
          required: true,
          message: "Vui lòng nhập tên",
          trigger: "submit"
        },
        username: {
          required: true,
          message: "Vui lòng nhập username",
          trigger: "submit"
        },
        password: {
          required: true,
          message: "Vui lòng nhập mật khẩu",
          trigger: "submit"
        },
        phone: {
          required: true,
          message: "Vui lòng nhập số điện thoại",
          trigger: "submit"
        },
        email: {
          required: true,
          trigger: "submit",
          validator: validateEmail
        }
      }
    };
  },
  created: function created() {
    this.getRoleData();
  },
  methods: {
    editData: function editData() {
      var _this = this;
      this.$refs["dataForm"].validate(function (valid) {
        if (valid) {
          var data = {
            info: _this.tempData,
            roleId: _this.roleId,
            areaId: _this.tempData.areaId
          };
          _this.loadingButton = true;
          user.update(_this.tempData.id, data).then(function (res) {
            if (res.status) {
              _this.$message({
                type: "success",
                message: "Cập nhật tài khoản thành công"
              });
              _this.dialogVisible = false;
              _this.$emit("update:success");
            }
          }).finally(function () {
            _this.loadingButton = false;
          });
        }
      });
    },
    getRoleData: function getRoleData() {
      var _this2 = this;
      (0, _role.getRoles)().then(function (res) {
        _this2.roles = res.data.data;
      });
    },
    handleEdit: function handleEdit(data) {
      var _this3 = this;
      this.dialogStatus = "edit";
      this.dialogVisible = true;
      this.$nextTick(function () {
        _this3.$refs["dataForm"].clearValidate();
        _this3.tempData = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, data), {}, {
          areaId: data.area && data.area.id
        });
        _this3.roleId = data.role && data.role.id;
      });
    },
    handleAdd: function handleAdd() {
      var _this4 = this;
      this.dialogVisible = true;
      this.dialogStatus = "add";
      this.$nextTick(function () {
        _this4.$refs["dataForm"].clearValidate();
        _this4.tempData = {};
        if (_this4.$store.state.user.info.area) {
          _this4.$set(_this4.tempData, "areaId", _this4.$store.state.user.info.area.id);
        }
        _this4.roleId = 2;
      });
    },
    createData: function createData() {
      var _this5 = this;
      this.$refs["dataForm"].validate(function (valid) {
        if (valid) {
          var data = {
            roleId: _this5.roleId,
            staff: _this5.tempData,
            areaId: _this5.tempData.areaId
          };
          _this5.loadingButton = true;
          user.add(data).then(function (res) {
            if (res.status) {
              _this5.$message({
                type: "success",
                message: "Tạo tài khoản thành công"
              });
              _this5.dialogVisible = false;
              _this5.$refs["dataForm"].resetFields();
              _this5.$emit("create:success");
            }
          }).finally(function () {
            _this5.loadingButton = false;
          });
        }
      });
    },
    handleAvatarSuccess: function handleAvatarSuccess(res, file) {
      this.dialogLoading = false;
      this.tempData.avatar = res;
    },
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      this.dialogLoading = true;
      var isJPG = file.type.includes("image");
      var isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.dialogLoading = false;
        this.$message.error("Avatar picture must be image format!");
      }
      if (!isLt2M) {
        this.dialogLoading = false;
        this.$message.error("Avatar picture size can not exceed 2MB!");
      }
      return isJPG && isLt2M;
    },
    getData: function getData(userId) {
      var _this6 = this;
      this.dialogVisible = true;
      this.dialogStatus = "edit";
      user.findOne(userId).then(function (res) {
        _this6.tempData = res.data;
      });
    }
  }
};