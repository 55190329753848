"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findAll = findAll;
exports.findOne = findOne;
exports.getSummary = getSummary;
exports.update = update;
var _request = _interopRequireDefault(require("@/utils/request"));
var BASE_URl = process.env.VUE_APP_BASE_ORDER_API;
function findAll(listQuery) {
  return (0, _request.default)({
    baseURL: BASE_URl,
    url: "/orderFood",
    method: "get",
    params: listQuery
  });
}
function getSummary(listQuery) {
  return (0, _request.default)({
    baseURL: BASE_URl,
    url: "/orderFood/summary",
    method: "get",
    params: listQuery
  });
}
function findOne(id) {
  return (0, _request.default)({
    baseURL: BASE_URl,
    url: "/orderFood/".concat(id),
    method: "get"
  });
}
function update(orderId, data) {
  return (0, _request.default)({
    baseURL: BASE_URl,
    url: "/orderFood/".concat(orderId, "/update"),
    method: "post",
    data: data
  });
}