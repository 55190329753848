"use strict";

var _interopRequireWildcard = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _objectSpread2 = _interopRequireDefault(require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
var news = _interopRequireWildcard(require("@/api/news"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    disableForm: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Tinymce: _Tinymce.default
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["token"])),
  data: function data() {
    return {
      rules: {
        title: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nhập tiêu đề"
        },
        intro: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nhập mô tả ngắn"
        },
        body: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nhập nội dung"
        }
      },
      dialogLoading: false,
      formData: {},
      dialogVisible: false,
      dialogStatus: "create",
      submitLoading: false,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA
    };
  },
  methods: {
    handleCreate: function handleCreate() {
      var _this = this;
      this.dialogVisible = true;
      this.dialogStatus = "create";
      this.$nextTick(function () {
        _this.$refs["form"].clearValidate();
        _this.formData = {};
        _this.$refs["editor"].setContent("");
      });
    },
    beforeUpload: function beforeUpload(file) {
      this.dialogLoading = true;
      var isJPG = file.type.includes("image");
      var isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.dialogLoading = false;
        this.$message.error("Chỉ chấp nhận ảnh dưới 2MB");
      }
      if (!isJPG) {
        this.dialogLoading = false;
        this.$message.error("Ảnh không đúng định dạng!");
      }
      return isJPG && isLt2M;
    },
    handleUpdate: function handleUpdate(row) {
      var _this2 = this;
      this.dialogVisible = true;
      this.dialogStatus = "update";
      this.$nextTick(function () {
        _this2.$refs["form"].clearValidate();
        _this2.formData = (0, _objectSpread2.default)({}, row);
        _this2.formData.start *= 1000;
        _this2.formData.end *= 1000;
        _this2.$refs["editor"].setContent(_this2.formData.body);
      });
    },
    handleUploadSuccess: function handleUploadSuccess(res, file) {
      this.formData.image = res;
      this.dialogLoading = false;
    },
    updateData: function updateData() {
      var _this3 = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          _this3.submitLoading = true;
          var data = {
            news: _this3.formData
          };
          news.update(_this3.formData.id, data).then(function (res) {
            if (res.status) {
              _this3.$message({
                type: "success",
                message: "Cập nhật tin tức thành công"
              });
              _this3.getList();
              _this3.dialogVisible = false;
            }
          }).finally(function () {
            _this3.submitLoading = false;
          });
        }
      });
    },
    createData: function createData() {
      var _this4 = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          _this4.submitLoading = true;
          var data = {
            news: _this4.formData
          };
          news.create(data).then(function () {
            _this4.$message({
              type: "success",
              message: "Thêm tin tức thành công"
            });
            _this4.getList();
            _this4.dialogVisible = false;
          }).finally(function () {
            _this4.submitLoading = false;
          });
        }
      });
    }
  }
};