var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "food-gallery" } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            "label-position": "top",
            "label-width": "80px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Ảnh (Giới hạn 3 tấm)", prop: "galleries" } },
            [
              _c("MultipleImageS3", {
                attrs: { fileList: _vm.fileList },
                on: {
                  "upload:success": _vm.uploadListSuccess,
                  "remove:success": _vm.handleRemove,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }