var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", attrs: { id: "food-list" } },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "300px" },
            attrs: {
              "prefix-icon": "el-icon-search",
              placeholder: "Tìm tên món ăn",
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.getTableData($event)
              },
            },
            model: {
              value: _vm.listQuery.search,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "search", $$v)
              },
              expression: "listQuery.search",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function ($event) {
                  return _vm.getTableData()
                },
              },
            },
            [_vm._v("Tìm")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-edit" },
              on: {
                click: function ($event) {
                  return _vm.handleAdd()
                },
              },
            },
            [_vm._v("Thêm")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "technician-table",
          attrs: { data: _vm.tableData, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              sortable: "",
              prop: "name",
              label: "Tên món",
              align: "center",
              width: "270",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "info-box" }, [
                      _c("div", { staticClass: "info" }, [
                        _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(row.name)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.type != "OTHER"
                        ? _c("img", {
                            staticClass: "image",
                            attrs: { src: _vm.$url(row.thumbnail), alt: "" },
                          })
                        : _c("img", {
                            staticClass: "image",
                            attrs: {
                              src: row.foodGalleries.length
                                ? _vm.$url(row.foodGalleries[0].thumbnail)
                                : "",
                              alt: "",
                            },
                          }),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { sortable: "", label: "Thông tin" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("b", [_vm._v("Danh mục:")]),
                      _vm._v(" " + _vm._s(row.menuFood && row.menuFood.name)),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("b", [_vm._v("Thành phần:")]),
                      _vm._v(" " + _vm._s(row.ingredient)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { sortable: "", label: "Giá" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("b", [_vm._v("Bán:")]),
                      _vm._v(" " + _vm._s(_vm.formatCurrency(row.finalPrice))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { sortable: "", label: "Vị trí", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-input", {
                      on: {
                        change: function ($event) {
                          return _vm.editData(row)
                        },
                      },
                      model: {
                        value: row.position,
                        callback: function ($$v) {
                          _vm.$set(row, "position", $$v)
                        },
                        expression: "row.position",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { sortable: "", label: "Ngày tạo" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v(_vm._s(_vm.formatDateTime(row.dateCreated))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "Hành động",
              width: "120",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "column",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              margin: "0",
                              "margin-bottom": "5px",
                            },
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(row)
                              },
                            },
                          },
                          [_vm._v("Chi tiết")]
                        ),
                        _vm._v(" "),
                        !row.isBlock
                          ? _c(
                              "el-button",
                              {
                                staticStyle: {
                                  margin: "0",
                                  "margin-bottom": "5px",
                                },
                                attrs: {
                                  type: "danger",
                                  plain: "",
                                  size: "mini",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleBlock(row)
                                  },
                                },
                              },
                              [_vm._v("Khóa")]
                            )
                          : _c(
                              "el-button",
                              {
                                staticStyle: {
                                  margin: "0",
                                  "margin-bottom": "5px",
                                },
                                attrs: { type: "warning", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleUnBlock(row)
                                  },
                                },
                              },
                              [_vm._v("Mở khóa")]
                            ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              margin: "0",
                              "margin-bottom": "5px",
                            },
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(row)
                              },
                            },
                          },
                          [_vm._v("Xóa")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "0" },
                            attrs: { size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleCopy(row)
                              },
                            },
                          },
                          [_vm._v("Nhân bản")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getTableData,
        },
      }),
      _vm._v(" "),
      _c("food-dialog", {
        ref: "dialog",
        attrs: { storeId: _vm.storeId, type: _vm.type },
        on: {
          "update:storeId": function ($event) {
            _vm.storeId = $event
          },
          "update:store-id": function ($event) {
            _vm.storeId = $event
          },
          "update:type": function ($event) {
            _vm.type = $event
          },
          "create:success": _vm.getTableData,
          "update:success": _vm.getTableData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }