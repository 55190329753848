"use strict";

var _interopRequireWildcard = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _NewsDialog = _interopRequireDefault(require("../news/components/NewsDialog"));
var notification = _interopRequireWildcard(require("@/api/notification"));
var _date = require("@/utils/date");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _NotificationDialog = _interopRequireDefault(require("./components/Notification-Dialog"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Pagination: _Pagination.default,
    Notification: _NotificationDialog.default,
    NewsDialog: _NewsDialog.default
  },
  data: function data() {
    return {
      listLoading: false,
      total: 0,
      listQuery: {
        page: 1,
        limit: 10,
        target: "CUSTOMER"
      },
      tableData: []
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    areas: function areas(state) {
      return state.area.list;
    }
  })),
  created: function created() {
    this.getTableData();
    this.$store.dispatch("area/fetchList");
  },
  methods: {
    handleDelete: function handleDelete(row) {
      var _this = this;
      this.$confirm("Thông báo này sẽ bị xóa, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        notification.remove(row.id).then(function () {
          _this.$message({
            type: "success",
            message: "Thành công"
          });
          _this.getTableData();
        });
      });
    },
    handleUnBlock: function handleUnBlock(row) {
      var _this2 = this;
      this.$confirm("Thông báo này sẽ được hiển thị, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        var data = {
          notification: {
            isBlock: false
          }
        };
        notification.update(row.id, data).then(function () {
          row.isBlock = false;
          _this2.$message({
            type: "success",
            message: "Thành công"
          });
        });
      });
    },
    handleNotify: function handleNotify(row) {
      var _this3 = this;
      this.$confirm("Thông báo này sẽ được gửi, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        notification.notice(row.id).then(function () {
          _this3.$message({
            type: "success",
            message: "Thành công"
          });
        });
      });
    },
    handleBlock: function handleBlock(row) {
      var _this4 = this;
      this.$confirm("Thông báo này sẽ bị ẩn, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        var data = {
          notification: {
            isBlock: true
          }
        };
        notification.update(row.id, data).then(function () {
          row.isBlock = true;
          _this4.$message({
            type: "success",
            message: "Thành công"
          });
        });
      });
    },
    viewNews: function viewNews(news) {
      this.$refs["news-dialog"].handleUpdate(news);
    },
    formatDate: function formatDate(n) {
      return (0, _date.formatDate)(n);
    },
    handleEdit: function handleEdit(row) {
      this.$refs["dialog"].handleUpdate(row);
    },
    handleAdd: function handleAdd() {
      this.$refs["dialog"].handleCreate();
    },
    getTableData: function getTableData() {
      var _this5 = this;
      this.listLoading = true;
      notification.findAll(this.listQuery).then(function (res) {
        _this5.tableData = res.data.data;
        _this5.total = res.data.total;
      }).finally(function () {
        _this5.listLoading = false;
      });
    }
  }
};