"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      visible: false,
      form: {
        page: 1,
        limit: 50
      },
      rules: {
        page: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nhập số trang"
        },
        limit: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nhập số bản ghi muốn xuất"
        }
      }
    };
  },
  methods: {
    handleOpen: function handleOpen(page, limit) {
      this.form.page = page;
      this.form.limit = limit;
      this.visible = true;
      this.$refs["form"].clearValidate();
    },
    handleExport: function handleExport() {
      var _this = this;
      this.$refs["form"].validate(function (valid) {
        if (valid && _this.form.limit <= 5000) {
          _this.$emit("export", {
            page: _this.form.page,
            limit: _this.form.limit
          });
        } else if (_this.form.limit > 5000) {
          _this.$message({
            message: "Bạn đã nhập quá 5000 bản ghi. Vui lòng kiểm tra lại",
            type: "info"
            // showClose: true,
            // duration: 3000,
          });
        }
      });
    }
  }
};