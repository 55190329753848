var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "margin-bottom": "10px",
            display: "flex",
            "align-items": "end",
          },
        },
        [
          _c(
            "div",
            [
              _c("label", [_vm._v("Khu vực")]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticStyle: { width: "250px" },
                  attrs: {
                    placeholder: "",
                    filterable: false,
                    disabled: _vm.$store.state.user.info.area,
                  },
                  on: { change: _vm.getData },
                  model: {
                    value: _vm.areaId,
                    callback: function ($$v) {
                      _vm.areaId = $$v
                    },
                    expression: "areaId",
                  },
                },
                _vm._l(_vm.areas, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.areaId
            ? _c("el-alert", {
                staticStyle: { width: "fit-content", "margin-left": "10px" },
                attrs: {
                  title: "Vui lòng chọn khu vực để xem danh sách",
                  type: "warning",
                  effect: "light",
                  "show-icon": "",
                  closable: false,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, border: "", stripe: "" } },
        [
          _c("el-table-column", { attrs: { type: "index" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Loại" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(_vm._s(_vm.PARAMS[row.param]))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Giá trị", prop: "value" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm.checkParamsIsHasContent(row.param)
                      ? _c("div", {
                          staticClass: "value-box",
                          domProps: { innerHTML: _vm._s(row.content) },
                        })
                      : _c("div", {
                          domProps: { innerHTML: _vm._s(row.value) },
                        }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Hành động" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(row)
                          },
                        },
                      },
                      [_vm._v("Cập nhật")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            visible: _vm.dialogVisible,
            width: "800px",
            id: "config-dialog",
            title: _vm.dialogStatus == "edit" ? "Cập nhật" : "Thêm",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading",
                },
              ],
              ref: "form",
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                "label-position": "top",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Loại", prop: "param" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.formData.param,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "param", $$v)
                                },
                                expression: "formData.param",
                              },
                            },
                            _vm._l(_vm.PARAMS, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item, value: index },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.isUploadImage
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Giá trị", prop: "value" } },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.formData.value,
                                    expression: "formData.value",
                                  },
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: {
                                      alias: "decimal",
                                      groupSeparator: ".",
                                      rightAlign: false,
                                      autoGroup: true,
                                      repeat: 20,
                                      autoUnmask: true,
                                      allowMinus: false,
                                    },
                                    expression:
                                      "{\n                alias: 'decimal',\n                groupSeparator: '.',\n                rightAlign: false,\n                autoGroup: true,\n                repeat: 20,\n                autoUnmask: true,\n                allowMinus: false\n              }",
                                  },
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.formData.value },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.formData,
                                      "value",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm.isContentUseTiny
                    ? _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Mô tả", prop: "content" } },
                            [
                              _c("Tinymce", {
                                ref: "editor",
                                attrs: { width: "100%", height: 400 },
                                model: {
                                  value: _vm.formData.content,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "content", $$v)
                                  },
                                  expression: "formData.content",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Giá trị", prop: "value" } },
                            [
                              _c("SingleImageS3", {
                                attrs: { pathImage: _vm.formData.value },
                                on: {
                                  "upload:success": _vm.handleUploadSuccess,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingButton },
                  on: {
                    click: function ($event) {
                      return _vm.editData()
                    },
                  },
                },
                [_vm._v("Đồng ý")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }