"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = create;
exports.findAll = findAll;
exports.update = update;
var _request = _interopRequireDefault(require("@/utils/request"));
function findAll(listQuery) {
  return (0, _request.default)({
    url: '/news',
    params: listQuery
  });
}
function create(data) {
  return (0, _request.default)({
    url: '/news',
    method: 'post',
    data: data
  });
}
function update(newsId, data) {
  return (0, _request.default)({
    url: "/news/".concat(newsId, "/update"),
    method: 'post',
    data: data
  });
}