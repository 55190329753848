"use strict";

var _interopRequireWildcard = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.string.includes");
require("core-js/modules/es7.array.includes");
var _objectSpread2 = _interopRequireDefault(require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var format = _interopRequireWildcard(require("@/utils/format"));
var _config = require("@/api/config");
var _enum = require("@/utils/enum");
var _vuex = require("vuex");
var _lodash = require("lodash");
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Tinymce: _Tinymce.default
  },
  data: function data() {
    return {
      configSystem: process.env.VUE_APP_CONFIG_SYSTEM,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      baseUrl: process.env.VUE_APP_BASE_API,
      PARAMS: _enum.PARAMS,
      tempData: {},
      formData: {},
      listQuery: {},
      isLoading: false,
      loadingButton: false,
      tip: {},
      dialogStatus: "",
      dialogVisible: false,
      dialogLoading: false,
      rules: {
        value: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nhập giá trị"
        }
      },
      tableData: []
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    areas: function areas(state) {
      return state.area.list;
    }
  })), (0, _vuex.mapGetters)(["token"])), {}, {
    isUploadImage: function isUploadImage() {
      var list = ["ORDER_TRANSPORT_BANNER", "ORDER_DELIVERY_BANNER", "ORDER_FOOD_BANNER", "ORDER_DELIVERY_BANNER_DETAIL", "ORDER_TRANSPORT_BANNER_DETAIL", "HOME_TOP_BANNER", "ORDER_TRANSPORT_CONTENT", "SHIP_FEE_CONTENT"];
      return list.includes(this.formData.param);
    },
    isContentUseTiny: function isContentUseTiny() {
      var list = ["ORDER_TRANSPORT_CONTENT", "SHIP_FEE_CONTENT"];
      return list.includes(this.formData.param);
    }
  }),
  created: function created() {
    this.getData();
    this.$store.dispatch("area/fetchList");
    this.initializeListQueryAreaId();
  },
  methods: {
    handleUploadSuccess: function handleUploadSuccess(res, file) {
      this.dialogLoading = false;
      this.$set(this.tempData, "thumbnail", res.data.path);
      this.$set(this.formData, "value", res.data.path);
    },
    beforeUpload: function beforeUpload(file) {
      this.dialogLoading = true;
      var isJPG = file.type.includes("image");
      var isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.dialogLoading = false;
        this.$message.error("Chỉ chấp nhận ảnh dưới 2MB");
      }
      if (!isJPG) {
        this.dialogLoading = false;
        this.$message.error("Avatar picture must be image format!");
      }
      return isJPG && isLt2M;
    },
    formatVND: function formatVND(n) {
      return format.formatCurrency(n);
    },
    checkParamsIsHasContent: function checkParamsIsHasContent(param) {
      var list = ["ORDER_TRANSPORT_CONTENT", "SHIP_FEE_CONTENT"];
      return list.includes(param);
    },
    editData: function editData() {
      var _this = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          _this.loadingButton = true;
          var data = {
            config: _this.formData
          };
          _config.configApi.update(_this.formData.id, data).then(function () {
            _this.$message({
              type: "success",
              message: "Cập nhật thành công"
            });
            _this.dialogVisible = false;
            _this.getData();
          }).finally(function () {
            _this.loadingButton = false;
          });
        }
      });
    },
    handleCreate: function handleCreate() {
      var _this2 = this;
      this.dialogStatus = "create";
      this.dialogVisible = true;
      this.$nextTick(function () {
        _this2.$refs["form"].resetFields();
        _this2.$refs["editor"].setContent("");
        _this2.formData = {};
      });
    },
    handleEdit: function handleEdit(item) {
      console.log(item);
      this.dialogStatus = "edit";
      this.dialogVisible = true;
      this.formData = (0, _objectSpread2.default)({}, item);
      this.tempData = {
        thumbnail: item.value
      };
      this.$refs["editor"].setContent(item.content);
    },
    getData: function getData() {
      var _this3 = this;
      var excludeList = ["COMMISSION_FOOD", "COMMISSION_DELIVERY", "COMMISSION_TRANSPORT", "DRIVER_FIND_DISTANCE", "DRIVER_TOTAL_ORDER_ACCEPT", "CUSTOMER_MAX_PARALLEL_ORDER"];
      this.isLoading = true;
      _config.configApi.findAll(this.listQuery).then(function (res) {
        _this3.tableData = res.data.configs.filter(function (e) {
          return !excludeList.includes(e.param);
        });
      }).finally(function () {
        _this3.isLoading = false;
      });
    },
    initializeListQueryAreaId: function initializeListQueryAreaId() {
      // Kiểm tra nếu this.$store.state.user.info.area tồn tại và có giá trị id
      if (this.$store.state.user.info.area && this.$store.state.user.info.area.id) {
        this.listQuery.areaId = this.$store.state.user.info.area.id;
      }
    }
  }
};