"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.configOrderApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var configOrderApi = exports.configOrderApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/configOrder",
      params: params
    });
  },
  init: function init(data) {
    return (0, _request.default)({
      url: "/configOrder/init",
      data: data,
      method: "post"
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/configOrder/".concat(id, "/update"),
      data: data,
      method: "post"
    });
  }
};