"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _withdraw = _interopRequireDefault(require("@/views/withdraw"));
var _deposit = _interopRequireDefault(require("@/views/deposit"));
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    driverId: Number
  },
  components: {
    Withdraw: _withdraw.default,
    Deposit: _deposit.default
  }
};