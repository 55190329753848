"use strict";

var _interopRequireWildcard = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var dashbord = _interopRequireWildcard(require("@/api/dashboard"));
var _format = require("@/utils/format");
var _enum = require("@/utils/enum");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ["areaId"],
  data: function data() {
    return {
      tableData: [],
      STORE_TYPE: _enum.STORE_TYPE
    };
  },
  watch: {
    areaId: {
      handler: function handler(val) {
        this.getTableData();
      }
    }
  },
  created: function created() {
    this.getTableData();
  },
  methods: {
    formatCurrency: function formatCurrency(n) {
      return (0, _format.formatCurrency)(n);
    },
    getTableData: function getTableData() {
      var _this = this;
      dashbord.topStore({
        areaId: this.areaId,
        limit: 20
      }).then(function (res) {
        _this.tableData = res.data;
      });
    }
  }
};