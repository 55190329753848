"use strict";

var _interopRequireWildcard = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _date = require("@/utils/date");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _FoodDialog = _interopRequireDefault(require("./FoodDialog"));
var food = _interopRequireWildcard(require("@/api/food"));
var _format = require("@/utils/format");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Pagination: _Pagination.default,
    FoodDialog: _FoodDialog.default
  },
  props: {
    storeId: {
      type: Number,
      default: null
    },
    type: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      listLoading: false,
      total: 0,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      listQuery: {
        page: 1,
        limit: 10,
        search: "",
        isBlock: null
      },
      tableData: []
    };
  },
  watch: {
    storeId: function storeId() {
      this.getTableData();
    }
  },
  methods: {
    formatCurrency: function formatCurrency(n) {
      return (0, _format.formatCurrency)(n);
    },
    handleEdit: function handleEdit(row) {
      this.$refs["dialog"].handleEdit(row);
    },
    handleCopy: function handleCopy(row) {
      this.$refs["dialog"].handleCopy(row);
    },
    handleAdd: function handleAdd() {
      this.$refs["dialog"].handleAdd();
    },
    handleImgError: function handleImgError(event) {
      event.currentTarget.src = require("@/assets/images/avatar_default.svg");
    },
    handleUnBlock: function handleUnBlock(row) {
      var _this = this;
      this.$confirm("Món ăn này sẽ được mở khóa, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        var data = {
          food: {
            isBlock: false
          }
        };
        food.update(row.id, data).then(function () {
          row.isBlock = false;
          _this.$message({
            type: "success",
            message: "Mở khóa thành công"
          });
        });
      });
    },
    handleBlock: function handleBlock(row) {
      var _this2 = this;
      this.$confirm("Món ăn này sẽ bị khóa, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        var data = {
          food: {
            isBlock: true
          }
        };
        food.update(row.id, data).then(function () {
          row.isBlock = true;
          _this2.$message({
            type: "success",
            message: "Khóa thành công"
          });
        });
      });
    },
    handleDelete: function handleDelete(row) {
      var _this3 = this;
      this.$confirm("Món ăn này sẽ bị xóa, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        food.remove(row.id).then(function () {
          _this3.$message({
            type: "success",
            message: "Xóa thành công"
          });
          _this3.getTableData();
        });
      });
    },
    getTableData: function getTableData() {
      var _this4 = this;
      this.listLoading = true;
      this.listQuery.storeId = this.storeId;
      food.findAll(this.listQuery).then(function (res) {
        _this4.tableData = res.data.data;
        _this4.total = res.data.total;
      }).finally(function () {
        _this4.listLoading = false;
      });
    },
    formatDateTime: function formatDateTime(timestamp) {
      return (0, _date.formatDateTime)(timestamp);
    },
    editData: function editData(row) {
      var _this5 = this;
      var data = {
        food: {
          position: row.position
        }
      };
      food.update(row.id, data).then(function (res) {
        _this5.getTableData();
      });
    }
  }
};