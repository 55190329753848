var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "300px" },
            attrs: {
              "prefix-icon": "el-icon-search",
              placeholder: "Tìm code, tên, sđt",
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.getTableData($event)
              },
            },
            model: {
              value: _vm.listQuery.search,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "search", $$v)
              },
              expression: "listQuery.search",
            },
          }),
          _vm._v(" "),
          _c("el-date-picker", {
            staticClass: "filter-item",
            attrs: {
              type: "daterange",
              "range-separator": "To",
              format: "dd/MM/yyyy",
              "value-format": "yyyy-MM-dd",
              "start-placeholder": "Ngày bắt đầu",
              "end-placeholder": "Ngày kết thúc",
              "picker-options": _vm.pickerOptions,
            },
            model: {
              value: _vm.date,
              callback: function ($$v) {
                _vm.date = $$v
              },
              expression: "date",
            },
          }),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              attrs: { placeholder: "Loại đơn" },
              model: {
                value: _vm.listQuery.status,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "status", $$v)
                },
                expression: "listQuery.status",
              },
            },
            [
              _c("el-option", { attrs: { value: null, label: "Trạng thái" } }),
              _vm._v(" "),
              _vm._l(_vm.ORDER_DELIVERY_STATUS, function (item, index) {
                return _c("el-option", {
                  key: index,
                  attrs: { label: item, value: index },
                })
              }),
            ],
            2
          ),
          _vm._v(" "),
          !_vm.$store.state.user.info.area
            ? _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  staticStyle: { width: "140px" },
                  attrs: { placeholder: "Chọn khu vực" },
                  on: { change: _vm.getTableData },
                  model: {
                    value: _vm.listQuery.areaId,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "areaId", $$v)
                    },
                    expression: "listQuery.areaId",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "Tất cả", value: null } }),
                  _vm._v(" "),
                  _vm._l(_vm.areas, function (area) {
                    return _c("el-option", {
                      key: area.id,
                      attrs: { label: area.name, value: area.id },
                    })
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function ($event) {
                  return _vm.getTableData()
                },
              },
            },
            [_vm._v("Tìm")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("p", { staticStyle: { "font-weight": "bold" } }, [
        _vm._v("\n    Tổng lợi nhuận:\n    "),
        _c("span", { staticClass: "increase" }, [
          _vm._v(_vm._s(_vm.formatCurrency(_vm.totalRevenue))),
        ]),
      ]),
      _vm._v(" "),
      _c("p", { staticStyle: { "font-weight": "bold" } }, [
        _vm._v("\n    Tổng chiết khấu:\n    "),
        _c("span", { staticClass: "increase" }, [
          _vm._v(_vm._s(_vm.formatCurrency(_vm.totalIncome))),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { data: _vm.tableData, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Code", prop: "code", "min-width": "150px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticStyle: { "padding-left": "12px" } }, [
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("\n              Mã đơn:\n            "),
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(row.code) + " ")]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Tài xế: "),
                        ]),
                        _c(
                          "span",
                          [
                            !row.driver
                              ? _c("span", [_vm._v("--")])
                              : _c(
                                  "el-link",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewDriver(row.driver)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(row.driver.username) +
                                        " - " +
                                        _vm._s(row.driver.name) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Khách hàng: "),
                        ]),
                        _c(
                          "span",
                          [
                            !row.customer
                              ? _c("span", [_vm._v("--")])
                              : _c(
                                  "el-link",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewCustomer(row.customer)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          row.customer.name ||
                                            row.customer.phone
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Khu vực: "),
                        ]),
                        _vm._v(" "),
                        !row.area
                          ? _c("span", [_vm._v("--")])
                          : _c("span", [_vm._v(" " + _vm._s(row.area.name))]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Nội dung yêu cầu: "),
                        ]),
                        _vm._v(" "),
                        !row.detail
                          ? _c("span", [_vm._v("--")])
                          : _c("span", [_vm._v(" " + _vm._s(row.detail))]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type:
                            _vm.tagOptions[row.status] &&
                            _vm.tagOptions[row.status].type,
                          effect:
                            _vm.tagOptions[row.status] &&
                            _vm.tagOptions[row.status].effect,
                        },
                      },
                      [_vm._v(_vm._s(_vm.ORDER_DELIVERY_STATUS[row.status]))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Lộ trình", width: "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticStyle: { "padding-left": "12px" } }, [
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Khoảng cách: "),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.formatNumberVN(row.distance)) + " Km"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Điểm lấy: "),
                        ]),
                        _c("span", [_vm._v(" " + _vm._s(row.startAddress))]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Điểm giao: "),
                        ]),
                        _c("span", [_vm._v(" " + _vm._s(row.endAddress))]),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "Thông tin người nhận",
              prop: "code",
              "min-width": "150px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticClass: "pay" }, [
                      _c("li", [
                        _c("span", [_vm._v("Họ tên:")]),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(row.receiverName))]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("Số điện thoại:")]),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(row.receiverPhone))]),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "Chi tiết",
              "min-width": "300px",
              "header-align": "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticClass: "pay" }, [
                      _c("li", [
                        _c("span", [_vm._v("Số tiền theo Km:")]),
                        _vm._v(" "),
                        _c("span", { staticClass: "increase" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.formatCurrency(row.moneyDistance)) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("Số tiền tip:")]),
                        _vm._v(" "),
                        _c("span", { staticClass: "increase" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.formatCurrency(row.encourageFee)) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("Tổng tiền:")]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.formatCurrency(row.moneyFinal))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("Chiết khấu tài xế:")]),
                        _vm._v(" "),
                        _c("span", { staticClass: "increase" }, [
                          _vm._v(
                            "+ " + _vm._s(_vm.formatCurrency(row.moneyIncome))
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Lợi nhuận", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.status == "COMPLETE"
                      ? _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.formatCurrency(
                                  row.moneyFinal - row.moneyIncome
                                )
                              ) +
                              "\n        "
                          ),
                        ])
                      : _c("span", [_vm._v("--")]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Ngày tạo", prop: "dateCreated" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.formatDateTime(row.dateCreated)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _vm.showStatusCol
            ? _c("el-table-column", {
                attrs: { label: "Trạng thái" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _vm.ORDER_DELIVERY_STATUS[row.status] !=
                            _vm.ORDER_DELIVERY_STATUS.WAITING &&
                          _vm.ORDER_DELIVERY_STATUS[row.status] !=
                            _vm.ORDER_DELIVERY_STATUS.COMPLETE
                            ? _c(
                                "el-select",
                                {
                                  staticClass: "filter-item",
                                  attrs: { placeholder: "Trạng thái" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.changeStatus($event, row)
                                    },
                                  },
                                },
                                _vm._l(
                                  _vm.ORDER_ACTION,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: { label: item, value: index },
                                    })
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3715365746
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getTableData,
        },
      }),
      _vm._v(" "),
      _c("CustomerDialog", {
        ref: "customer-dialog",
        attrs: { dialogDisabled: true },
      }),
      _vm._v(" "),
      _c("driver-dialog", {
        ref: "driver-dialog",
        attrs: { dialogDisabled: true },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }