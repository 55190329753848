"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _store = require("@/api/store");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ["data"],
  data: function data() {
    return {
      rules: {
        username: {
          required: true,
          message: "Vui lòng nhập tài khoản",
          trigger: "submit"
        },
        password: {
          required: true,
          message: "Vui lòng nhập mật khẩu",
          trigger: "submit"
        }
      },
      form: {},
      loading: false
    };
  },
  methods: {
    onSubmit: function onSubmit() {
      var _this = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          var data = {
            password: _this.form.password,
            username: _this.form.username
          };
          _this.loading = true;
          (0, _store.account)(_this.form.id, data).then(function (res) {
            _this.$notify({
              type: "success",
              title: "Thông báo",
              message: "Cập nhật tài khoản cửa hàng thành công!"
            });
            _this.form.password = "";
            _this.form.username = res.data.username;
            _this.$emit("submit:success");
          }).finally(function () {
            _this.loading = false;
          });
        }
      });
    }
  },
  watch: {
    data: function data(val) {
      this.form = (0, _objectSpread2.default)({}, val);
    }
  }
};