"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tagOptions = exports.VoucherFormType = exports.VoucherDiscountType = exports.VoucherApplyType = exports.TYPE_CONTENT_DEFINE = exports.STORE_TYPE = exports.PARAMS = exports.ORDER_TYPE = exports.ORDER_TRANSPORT_STATUS = exports.ORDER_FOOD_STATUS = exports.ORDER_DELIVERY_STATUS = exports.ORDER_ACTION = exports.HomeBannerLayoutTrans = exports.HomeBannerLayout = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _PARAMS;
var STORE_TYPE = exports.STORE_TYPE = {
  FOOD: "Thức ăn, uống",
  OTHER: "Khác"
};
var ORDER_FOOD_STATUS = exports.ORDER_FOOD_STATUS = {
  WAITING: "Đang đợi",
  DELIVERING: "Đang giao",
  COMPLETE: "Hoàn thành",
  CANCEL: "Huỷ",
  NO_DELIVERY: "Không giao"
};
var ORDER_ACTION = exports.ORDER_ACTION = {
  DELIVERING: "Đang giao",
  NO_DELIVERY: "Không giao",
  CANCEL: "Huỷ đơn"
};
var ORDER_DELIVERY_STATUS = exports.ORDER_DELIVERY_STATUS = {
  WAITING: "Đang đợi",
  DELIVERING: "Đang giao",
  COMPLETE: "Hoàn thành",
  CANCEL: "Huỷ",
  NO_DELIVERY: "Không giao"
};
var ORDER_TRANSPORT_STATUS = exports.ORDER_TRANSPORT_STATUS = {
  WAITING: "Đang đợi",
  DELIVERING: "Đang giao",
  COMPLETE: "Hoàn thành",
  CANCEL: "Huỷ",
  NO_DELIVERY: "Không giao"
};
var TYPE_CONTENT_DEFINE = exports.TYPE_CONTENT_DEFINE = {
  ABOUT: "Thông tin chúng tôi",
  FAQ: "Câu hỏi thường gặp",
  HOW_TO_USE: "Cách sử dụng",
  SECURITY: "Bảo mật",
  TERM_CONDITION: "Điều khoản",
  HOW_TO_DEPOSIT: "Hướng dẫn nạp điểm",
  HOW_TO_USE_DRIVER: "Cách sử dụng (Tài xế)",
  FAQ_DRIVER: "Câu hỏi thường gặp (Tài xế)"
};
var ORDER_TYPE = exports.ORDER_TYPE = {
  FOOD: "Thức ăn",
  TRANSPORT: "Di chuyển",
  DELIVERY: "Giao hàng"
};
var PARAMS = exports.PARAMS = (_PARAMS = {
  FOOD: "Thức ăn",
  DELIVERY: "Giao hàng",
  TRANSPORT: "Di chuyển ",
  DRIVER_TOTAL_ORDER_ACCEPT: "Số lượng đơn tối đa tài xế nhận"
}, (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_PARAMS, "DRIVER_TOTAL_ORDER_ACCEPT", "Số lượng đơn tối đa tài xế nhận"), "CUSTOMER_MAX_PARALLEL_ORDER", "Số đơn tối đa đặt đồng thời cùng lúc"), "ORDER_TRANSPORT_BANNER", "Hình ảnh banner của dịch vụ gọi xe"), "ORDER_DELIVERY_BANNER", "Hình ảnh banner của dịch vụ giao hàng"), "ORDER_FOOD_BANNER", "Hình ảnh banner của dịch vụ thức ăn"), "ORDER_TRANSPORT_CONTENT", "Nội dung vận chuyển đặt hàng"), "SHIP_FEE_CONTENT", "Phí ship"), "DRIVER_FIND_DISTANCE", "Bán kính tài xế có thể nhận đơn"), "ORDER_DELIVERY_BANNER_DETAIL", "Hình ảnh banner của dịch vụ giao hàng chi tiết"), "ORDER_TRANSPORT_BANNER_DETAIL", "Hình ảnh banner của dịch vụ gọi xe chi tiết"), (0, _defineProperty2.default)(_PARAMS, "HOME_TOP_BANNER", "Hình ảnh đầu trang home"));
var tagOptions = exports.tagOptions = {
  WAITING: {
    type: "warning",
    effect: "plain"
  },
  DELIVERING: {
    type: "",
    effect: "plain"
  },
  COMPLETE: {
    type: "success",
    effect: "dark"
  },
  CANCEL: {
    type: "danger",
    effect: "dark"
  },
  NO_DELIVERY: {
    type: "danger",
    effect: "plain"
  }
};
var VoucherDiscountType = exports.VoucherDiscountType = {
  Amount: "AMOUNT",
  //Số tiền
  Percent: "PERCENT" //theo %
};
var VoucherApplyType = exports.VoucherApplyType = {
  All: "ALL",
  //Tất cả cửa hàng
  Select: "SELECT" //1 vài cửa hàng đc chọn
};
var VoucherFormType = exports.VoucherFormType = {
  Product: "PRODUCT",
  //tiền đồ ăn
  Ship: "SHIP" //phí ship
};
var HomeBannerLayout = exports.HomeBannerLayout = {
  Top: "TOP",
  Mid: "MID",
  Bottom: "BOTTOM"
};
var HomeBannerLayoutTrans = exports.HomeBannerLayoutTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, HomeBannerLayout.Top, "Trên"), HomeBannerLayout.Mid, "Giữa"), HomeBannerLayout.Bottom, "Dưới");