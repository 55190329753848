"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.$url = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var $url = exports.$url = function $url(url) {
  if (!url) {
    return "";
  }
  if (url.includes("http")) {
    return url;
  }
  return process.env.VUE_APP_BASE_URL_MEDIA + url;
};