var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: { data: _vm.tableData, border: "", stripe: "" },
    },
    [
      _c("el-table-column", {
        attrs: { "class-name": "text-center", type: "index", width: "50" },
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: {
          prop: "name",
          label: "Tên món",
          align: "center",
          width: "270",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("div", { staticClass: "info-box" }, [
                  _c("div", { staticClass: "info" }, [
                    _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(row.name)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "image",
                    attrs: { src: _vm.$url(row.thumbnail), alt: "" },
                  }),
                ]),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "Thành phần", prop: "ingredient" },
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { prop: "finalPrice", label: "Giá bán" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              return [_vm._v(_vm._s(_vm.formatCurrency(row.finalPrice)))]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "Hành động" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              return [
                row.type == "CREATE"
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleApprove(row)
                          },
                        },
                      },
                      [_vm._v("Chấp nhận tạo")]
                    )
                  : _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleApprove(row)
                          },
                        },
                      },
                      [_vm._v("Chấp nhận cập nhật")]
                    ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }