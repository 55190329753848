"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/050/050admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _date = require("@/utils/date");
var _format = require("@/utils/format");
var _PromotionDialog = _interopRequireDefault(require("./components/PromotionDialog"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _voucher = require("@/api/voucher");
var _enum = require("@/utils/enum");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    PromotionDialog: _PromotionDialog.default,
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      tableData: [],
      total: 0,
      listQuery: {
        page: 1,
        limit: 10
      },
      VoucherApplyType: _enum.VoucherApplyType,
      VoucherDiscountType: _enum.VoucherDiscountType
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    formatCurrency: function formatCurrency(n) {
      return (0, _format.formatCurrency)(n);
    },
    formatNumber: function formatNumber(n) {
      return (0, _format.formatNumberVN)(n);
    },
    handleUpdate: function handleUpdate(row) {
      this.$refs.promotionRef.handleUpdate(row);
    },
    handleCreate: function handleCreate() {
      this.$refs.promotionRef.handleCreate();
    },
    handleDelete: function handleDelete(row) {
      var _this = this;
      this.$confirm("Voucher này sẽ bị xóa, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        _voucher.voucherApi.delete(row.id).then(function () {
          _this.$message({
            type: "success",
            message: "Xóa thành công"
          });
          _this.getList();
        });
      });
    },
    formatDate: function formatDate(n) {
      return (0, _date.formatDate)(n);
    },
    getList: function getList() {
      var _this2 = this;
      _voucher.voucherApi.findAll(this.listQuery).then(function (res) {
        _this2.tableData = res.data.vouchers;
        _this2.total = res.data.total;
      });
    }
  }
};