var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            top: "30px",
            title: "Sắp xếp khu vực",
            visible: _vm.dialogVisible,
            width: _vm.dialogWidth,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "ElTableDraggable",
            {
              staticClass: "grabbable",
              attrs: { handle: ".handle", animate: 300 },
            },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    border: true,
                    data: _vm.tableData,
                    "row-key": "id",
                    "row-class-name": "handle",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { width: "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", {
                              staticClass: "handle-icon el-icon-rank",
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "class-name": "text-center",
                      type: "index",
                      width: "50",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Tên", prop: "name" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Ngày tạo" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.formatDateTime(row.dateCreated))
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingButton },
                  on: {
                    click: function ($event) {
                      return _vm.updateData()
                    },
                  },
                },
                [_vm._v("Lưu thay đổi")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }